import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-emulation',
  templateUrl: './emulation.component.html',
  styleUrls: ['./emulation.component.scss']
})
export class EmulationComponent implements OnInit {
  emulationForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initEmulationForm();
  }

  initEmulationForm() {
    this.emulationForm = this.fb.group({
      emailAddress: new FormControl(null, [Validators.required, Validators.email])
    })
  }

  emulateEAPUser() {
    let email = encodeURIComponent(this.emulationForm.controls['emailAddress'].value);
    let encryptedEmulateUrl = encodeURIComponent(`${environment.frXUI}/?service=impersonate&impusername=${email}&realm=/partner&ForceAuth=true#login`);
    let encryptedRpid = encodeURIComponent(environment.adfs);
    let baseURL = encodeURIComponent(`=${encryptedRpid}&RelayState=${encryptedEmulateUrl}`);
    location.href = `${environment.fed}?RelayState=RPID${baseURL}`
  }

}
