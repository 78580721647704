import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {
  ColDef, 
  RowModelType,
  GridReadyEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  GridApi,
} from 'ag-grid-community';

import { EntityService } from '../service/entity.service';
import { EntityType } from 'src/app/shared/models/entityType';

@Component({
  selector: 'app-entity-types',
  templateUrl: './entity-types.component.html',
  styleUrls: ['./entity-types.component.scss']
})
export class EntityTypesComponent {

  public defaultColDef: ColDef = {
    minWidth: 100,
    sortable: true,
  };

  private gridApi: GridApi;

  public rowData!: EntityType[];

  filters: Map<string, any> = new Map();

  filterForm = new FormGroup({
    entityTypeFilter: new FormControl(''),
    entityGroupTypeFilter: new FormControl('')
  });

  colDefs: ColDef[] = [
    { field: "entity_type_name", headerName: "Type Name", flex: 2 },
    { field: "entity_type_code", headerName: "Type Code", flex: 1 },
    { field: "entity_type_group.entity_type_group_name", headerName: "Type Group", flex: 2 }
  ];

  public rowModelType: RowModelType = "clientSide";
  public rowSelection: "single" | "multiple" = "single";
  public paginationPageSize = 20;
  public themeClass: string = "ag-theme-material";

  constructor(private service: EntityService) { }  

  ngAfterViewInit(): void {
  }

  onGridReady(params: GridReadyEvent<EntityType>) {
    this.gridApi = params.api;
    this.loadEntityTypes();  
  }

  ngOnInit(): void {
  }

  loadEntityTypes(): void {
    try {        
      this.service.getEntityTypes(
        0, 100,).subscribe(
          (data : any) => {
            if (data.total === 0) {
              alert("No entity types to display")
            }
            this.rowData = data.results;
          }
      );

    } catch (error : any) {
      
      alert(error.message);

    }
  }

}
