<div class="entity-details">

    <h2 style="color:black; text-align:left">Entities</h2>

    <ag-grid-angular
        style="width: 100%; height: 600px;"
        [columnDefs]="colDefs"
        [rowSelection]="rowSelection"
        [rowData]="rowData"
        [class]="themeClass"    
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
        
</div>