import { Component, Type } from '@angular/core';
import { PlanningYearService } from './service/planning-year.service';
import { CoincidentPeak } from 'src/app/shared/models/coincidentPeak';
import { MisoCPPlanningData, NonCPPlanningData, PlanningData } from 'src/app/shared/models/planningData';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MisoCoincidentPeak } from 'src/app/shared/models/coincidentPeak';
import { NonCoincidentPeak } from 'src/app/shared/models/coincidentPeak';
import { filter, update } from 'lodash';

interface PlanningYear {
  value: number,
  description: string
}

enum Season{
  summer = "summer",
  fall = "fall",
  winter = "winter",
  spring = "spring"
}

@Component({
  selector: 'planning-year',
  templateUrl: './planning-year.component.html',
  styleUrl: './planning-year.component.scss'
})
export class PlanningYearComponent {
  planningForm: FormGroup;
  planningYear: number = -1;
  planningYearOptions: PlanningYear[] = [];
  misoCPData: MisoCoincidentPeak[] = [];
  nonCPData: NonCoincidentPeak[] = [];
  misoCPViewData: MisoCPPlanningData;
  nonCPViewData: NonCPPlanningData;
  misoCPEntityUID: string;
  nonCPEntityUID: string;
  yearSet: boolean = false;
  nextYear = 0;

  planninigYearControl = new FormControl<number>(this.planningYear);

  constructor(private service: PlanningYearService, private fb: FormBuilder) {
  } 

  async ngOnInit(): Promise<void> {
    this.planningForm = this.fb.group({
      misoCPForm: this.fb.group({
        summer: "",
        fall: "",
        winter: "",
        spring: ""
      }),
      nonCPForm: this.fb.group({
        summer: "",
        fall: "",
        winter: "",
        spring: ""
      })
    })
    await this.getMisoCoincidentPeakData();
    await this.getNonCoincidentPeakData();
  }

  async getMisoCoincidentPeakData() {
    this.service.getMISOCPData().subscribe(
      (data : any) => {
        if (data.total === 0) {
          alert("No coincident peak data to display")
        }
        this.misoCPData = data.results;
        this.populatePlanniongYearOptions();
        this.misoCPEntityUID = this.misoCPData[0].entity_uid;
      }
    );
  }

  async getNonCoincidentPeakData() {
    this.service.getNonCPData().subscribe(
      (data : any) => {
        if (data.total === 0) {
          alert("No non coincident peak data to display")
        }
        this.nonCPData = data.results;
        this.nonCPEntityUID = this.nonCPData[0].entity_uid;
      }
    );
  }

  isYearSet() {
    return this.yearSet;
  }

  formIsDirty() {
    return this.planningForm.dirty;
  }
  
  populatePlanniongYearOptions() {
    let month: number;
    let year: number;
    let date: Date;
    
    this.misoCPData.forEach((data) => {
      date = new Date(data.effective_date);
      month = date.getMonth() + 1;
      if (month == 6) {
        year = date.getFullYear();
        if (year >= this.nextYear) {
          this.nextYear = year + 1;
        }
        this.planningYearOptions.push({
          "value": year, "description": `June ${year} - May ${year + 1}`
        })
      }
    });
    this.planningYearOptions = this.planningYearOptions.sort((a, b) => {
      return a.value - b.value
    });
    this.planningYearOptions.push({
      value: 0, description: "New Year"
    })
  }

  setPlanningYear() {
    this.yearSet = true;
    if (this.planningYear != 0) {
  
      let filteredData: CoincidentPeak[] = [];
  
      filteredData = this.filterCoincidentPeakData(this.misoCPData);

      if (filteredData.length > 0) {
        this.misoCPViewData = this.mapCoincidentPeakData(filteredData);
      } else {
        this.initializeNewMisoCPViewData();
      }

      filteredData = this.filterCoincidentPeakData(this.nonCPData);

      if (filteredData.length > 0) {
        this.nonCPViewData = this.mapCoincidentPeakData(filteredData);
      } else {
        this.initializeNewNonCPViewData();
      }

    } else { // you clocked New Year - initializing a fresh set of CP data
      this.initializeNewPlanningData();
    }
    this.setFormValues();
  }

  filterCoincidentPeakData(data: CoincidentPeak[]) : CoincidentPeak[] {
    let start = new Date(`${this.planningYear}-5-31 00:00:00`);
    let end = new Date(`${this.planningYear + 1}-3-2 00:00:00`);
    let filteredData: CoincidentPeak[] = [];
  
    data.forEach((data) => {
      if (new Date(data.effective_date).getTime() > start.getTime()) {
        if (new Date(data.effective_date).getTime() < end.getTime()) {
          filteredData.push(data);
        }
      }
    });
    return filteredData;
  }

  setFormValues() {
    this.planningForm.get(`misoCPForm.summer`)?.patchValue(this.misoCPViewData.summer.peak_hour);
    this.planningForm.get(`misoCPForm.fall`)?.patchValue(this.misoCPViewData.fall.peak_hour);
    this.planningForm.get(`misoCPForm.winter`)?.patchValue(this.misoCPViewData.winter.peak_hour);
    this.planningForm.get(`misoCPForm.spring`)?.patchValue(this.misoCPViewData.spring.peak_hour);
    this.planningForm.get(`nonCPForm.summer`)?.patchValue(this.nonCPViewData.summer.peak_hour);
    this.planningForm.get(`nonCPForm.fall`)?.patchValue(this.nonCPViewData.fall.peak_hour);
    this.planningForm.get(`nonCPForm.winter`)?.patchValue(this.nonCPViewData.winter.peak_hour);
    this.planningForm.get(`nonCPForm.spring`)?.patchValue(this.nonCPViewData.spring.peak_hour);
  }

  clearForm() {
    this.planningYear = -1;
    this.yearSet = false;
    this.misoCPViewData = {
      fall: new MisoCoincidentPeak(),
      winter: new MisoCoincidentPeak(),
      spring: new MisoCoincidentPeak(),
      summer: new MisoCoincidentPeak()
    };
    
    this.nonCPViewData = {
      fall: new NonCoincidentPeak(),
      winter: new NonCoincidentPeak(),
      spring: new NonCoincidentPeak(),
      summer: new NonCoincidentPeak()
    };
  }

  mapCoincidentPeakData(data: CoincidentPeak[]): PlanningData {
    let planningData: PlanningData | undefined = undefined;

    data.forEach((result) => {
      if (!planningData) {
        if (result instanceof MisoCoincidentPeak) {
          planningData = new MisoCPPlanningData();
        } else {
          planningData = new NonCPPlanningData();
        }
      }

      let month = new Date(result.effective_date).getMonth() + 1;
      switch(month) {
        case 3: {
          planningData.spring = result;
          break;
        }
        case 6: {
          planningData.summer = result;
          break;
        }
        case 9: {
          planningData.fall = result;
          break;
        }
        case 12: {
          planningData.winter = result;
          break;
        }
      }
    });

    // this should never get hit - 
    if (!planningData) {
      planningData = new MisoCPPlanningData();
    }
    
    this.fillEmptySeasons(planningData);

    return planningData;
  }

  fillEmptySeasons(data: PlanningData) {
    if (data instanceof MisoCPPlanningData) {
      if (!data.fall) {
        data.fall = this.initializeNewMisoCPData(this.planningYear, Season.fall);
      }
  
      if (!data.winter) {
        data.winter = this.initializeNewMisoCPData(this.planningYear, Season.winter);
      }
  
      if (!data.spring) {
        data.spring = this.initializeNewMisoCPData(this.planningYear, Season.spring);
      }
  
      if (!data.summer) {
        data.summer = this.initializeNewMisoCPData(this.planningYear, Season.summer);
      }
    } else {
      if (!data.fall) {
        data.fall = this.initializeNewNonCPData(this.planningYear, Season.fall);
      }
  
      if (!data.winter) {
        data.winter = this.initializeNewNonCPData(this.planningYear, Season.winter);
      }
  
      if (!data.spring) {
        data.spring = this.initializeNewNonCPData(this.planningYear, Season.spring);
      }
  
      if (!data.summer) {
        data.summer = this.initializeNewNonCPData(this.planningYear, Season.summer);
      }
    }
  }

  initializeNewPlanningData() {
    this.initializeNewMisoCPViewData();

    this.initializeNewNonCPViewData();
  }

  initializeNewMisoCPViewData() {
    this.misoCPViewData = new MisoCPPlanningData();
    this.misoCPViewData.summer = this.initializeNewMisoCPData(this.nextYear, Season.summer);
    this.misoCPViewData.fall = this.initializeNewMisoCPData(this.nextYear, Season.summer);
    this.misoCPViewData.winter = this.initializeNewMisoCPData(this.nextYear, Season.summer);
    this.misoCPViewData.spring = this.initializeNewMisoCPData(this.nextYear, Season.summer);
  }

  initializeNewNonCPViewData() {
    this.nonCPViewData = new NonCPPlanningData();
    this.nonCPViewData.summer = this.initializeNewNonCPData(this.nextYear, Season.summer);
    this.nonCPViewData.fall = this.initializeNewNonCPData(this.nextYear, Season.fall);
    this.nonCPViewData.winter = this.initializeNewNonCPData(this.nextYear, Season.winter);
    this.nonCPViewData.spring = this.initializeNewNonCPData(this.nextYear, Season.spring);    
  }

  initializeNewMisoCPData(year: number, season: Season): MisoCoincidentPeak {
    let data = this.initializeCPSeasons(new MisoCoincidentPeak(), year, season);
    data.entity_uid = this.misoCPEntityUID;
    return data;
  }

  initializeNewNonCPData(year: number, season: Season): NonCoincidentPeak {
    let data = this.initializeCPSeasons(new NonCoincidentPeak(), year, season);
    data.entity_uid = this.nonCPEntityUID;
    return data;
  }

  initializeCPSeasons(data: CoincidentPeak, year: number, season: Season): CoincidentPeak {
    switch(season) {
      case Season.summer: {
        data.effective_date = new Date(`${year}-06-01T05:00:00.000`);
        data.termination_date = new Date(`${year}-09-01T05:00:00.000`);
        break;
      }
      case Season.fall: {
        data.effective_date = new Date(`${year}-09-01T05:00:00.000`);
        data.termination_date = new Date(`${year}-12-01T05:00:00.000`);
        break;
      }
      case Season.winter: {
        data.effective_date = new Date(`${year+1}-12-01T05:00:00.000`);
        data.termination_date = new Date(`${year+1}-03-01T05:00:00.000`);
        break;
      }
      case Season.spring: {
        data.effective_date = new Date(`${year+1}-03-01T05:00:00.000`);
        data.termination_date = new Date(`${year+1}-06-01T05:00:00.000`);
        break;
      }
    }
    return data;
  }

  createUpdateData() {
    let updatedCPData: CoincidentPeak[] = [];
    let value: "";

    Object.values(Season).forEach((season) => {
      value = this.planningForm.get(`misoCPForm.${season}`)?.value;

      if (this.misoCPViewData[season].peak_hour !== value) {
        this.misoCPViewData[season].peak_hour = value;
        updatedCPData.push(this.misoCPViewData[season]);
      }
      
      value = this.planningForm.get(`nonCPForm.${season}`)?.value;

      if (this.nonCPViewData[season].peak_hour !== value) {
        this.nonCPViewData[season].peak_hour = value;
        updatedCPData.push(this.nonCPViewData[season]);
      }
    });

    updatedCPData.forEach((data) => {
      this.service.createUpdateCoincidentPeakData(data).subscribe();
    });
  }
}
