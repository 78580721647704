<div class="clearfix hero">
  <div class="hero__background">
    <div class="title">
      <h3>Electric Settlement System</h3>
    </div>
  </div>

  <div class="hero__login">
    <app-login></app-login>
  </div>
</div>
