import { Component } from "@angular/core";
import { LoadingSpinnerService } from "./service/loading-spinner.service";

@Component({
    selector: "loading-spinner",
    templateUrl: "./loading-spinner.component.html",
    styleUrls: ["./loading-spinner.component.scss"]
  })
  export class LoadingSpinnerComponent {

  spinnerActive: boolean = true;

  constructor(
    public spinnerService: LoadingSpinnerService
  ) {
    this.spinnerService.showSpinner.subscribe(this.showSpinner.bind(this));
  }

  showSpinner = (state: boolean): void => {
    this.spinnerActive = state;
  };

}
