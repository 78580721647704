import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { EntityDetailResponse } from 'src/app/shared/models/entityDetailResponse';
import { EntityTypeResponse } from 'src/app/shared/models/entityTypeResponse';
import { EntityTypeGroupResponse } from 'src/app/shared/models/entityTypeGroupResponse';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(private http: HttpClient) { }

  getEntityDetails(
    pageNumber = 0, 
    pageSize = 25,
    filters: Map<string, any>) : Observable<EntityDetailResponse> {

    let start = pageNumber * pageSize;

    let params = new HttpParams();

    params = params.set('start', start);
    params = params.set('limit', pageSize);

    if (filters.size > 0) {
      for(let [key, value] of filters) {
        params = params.set(key, value);
      }
    }

    const requestOptions = { params: params };
    
    return this.http.get<EntityDetailResponse>('entity', requestOptions )
    .pipe(catchError(this.handleError));
  }

  getEntityTypes(
    pageNumber = 0, 
    pageSize = 25) : Observable<EntityTypeResponse> {

    let start = pageNumber * pageSize;

    let params = new HttpParams();

    params = params.set('start', start);
    params = params.set('limit', pageSize);

    const requestOptions = { params: params };
    
    return this.http.get<EntityTypeResponse>('entity_type_code', requestOptions )
    .pipe(catchError(this.handleError));
  }

  getEntityTypeGroups(
    pageNumber = 0, 
    pageSize = 25) : Observable<EntityTypeGroupResponse> {

    let start = pageNumber * pageSize;

    let params = new HttpParams();

    params = params.set('start', start);
    params = params.set('limit', pageSize);

    const requestOptions = { params: params };

    return this.http.get<EntityTypeGroupResponse>('entity_type_group', requestOptions )
    .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('There is an issue with the client or network:', error.error);
    } else {
      console.error('Server-side error: ', error.error)
    }

    return throwError(() => new Error('Cannot retrieve entity data from the server. Please try again.'));
  }
}
