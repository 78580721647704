import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneuiframeworkComponent } from './oneuiframework.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { CardContainerComponent } from './components/card-container/card-container.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { FormComponent } from './components/form/form.component';
import { InputComponent } from './components/input/input.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { TestComponent } from './components/test/test.component';
import { InputDirective } from './directives/input.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TermComponent } from './components/term/term.component';
import { RouterModule } from '@angular/router';
import { InputMaskDirective } from './directives/input-mask/input-mask.directive';
import { CarouselComponent } from './components/carousel/carousel.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SplunkLoggerService } from './services/splunk-api.service';
import { SplunkInterceptor } from './services/splunk-interceptor';
import { SplunkManager } from './services/splunkmanager.service';

@NgModule({
  declarations: [
    OneuiframeworkComponent,
    HeaderComponent,
    FooterComponent,
    AccordionComponent,
    DropdownComponent,
    CardContainerComponent,
    CalendarComponent,
    CheckboxComponent,
    FormComponent,
    InputComponent,
    RadiobuttonComponent,
    TestComponent,
    InputDirective,
    TermComponent,
    InputMaskDirective,
    CarouselComponent,
    LoadingComponent,
  ],
  imports: [FormsModule, ReactiveFormsModule, BrowserModule, RouterModule],
  exports: [
    OneuiframeworkComponent,
    HeaderComponent,
    AccordionComponent,
    InputComponent,
    InputDirective,
    DropdownComponent,
    FooterComponent,
    TermComponent,
    InputMaskDirective,
    CarouselComponent,
    LoadingComponent,
  ],
  providers: [SplunkLoggerService, SplunkInterceptor, SplunkManager],
})
export class OneuiframeworkModule {}
