import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';

@Component({
  selector: 'ameren-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements ControlValueAccessor {


  @Input() options: any[]
  @Input() value: string;
  @Input() text: string;
  @Input() selectedValue: string;
  @Input() optionLabel: string;
  
  @Input() frmControl: FormControl;
  @Input() frmControlName: string;

  @ViewChild(FormControlDirective, {static: true})
  formControlDirective?: FormControlDirective;


  constructor(private controlContainer: ControlContainer) {
    
  }

  get control() {
    return this.frmControl || this.controlContainer?.control?.get(this.frmControlName);
  }


  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj);
  }


}
