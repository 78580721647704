<div class="overrides">

    <h1 style="color:black; text-align:left">Spa Overrides</h1>

    <div class="scrolling-container">

        <form 
            class="override-filters ng-container"
            [formGroup]="filterForm"
            (ngSubmit)="$event.preventDefault(); applyFilters()"
        >

            <h3 style="color:black; text-align:left">Filters</h3>

            <mat-form-field>
                <mat-label>Start Time</mat-label>
                <input 
                    formControlName="startTimeFilter"
                    matInput [matDatepicker]="startTimeFilter"
                >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startTimeFilter"></mat-datepicker-toggle>
                <mat-datepicker #startTimeFilter></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>End Time</mat-label>
                <input 
                    formControlName="endTimeFilter"
                    matInput [matDatepicker]="endTimeFilter"
                >
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endTimeFilter"></mat-datepicker-toggle>
                <mat-datepicker #endTimeFilter></mat-datepicker>
            </mat-form-field>

            <button 
                class="apply-filter" 
                type="submit" 
                mat-raised-button 
                color="primary" 
                [disabled]="!filterForm.get('startTimeFilter')?.dirty && !filterForm.get('endTimeFilter')?.dirty"
            >Apply</button>
        </form>

        <table mat-table class="overrides-table mat-elevation-z0"
            matSort matSortDisableClear 
            matSortActive="uidservicepoint" 
            matSortDirection="asc"
            [dataSource]="overrides" multiTemplateDataRows>

            <ng-container matColumnDef="uidservicepoint" sticky>

                <th mat-header-cell *matHeaderCellDef>UID Service Point</th>

                <td mat-cell *matCellDef="let override">{{override.uidservicepoint}}</td>

            </ng-container>

            <ng-container matColumnDef="starttime">

                <th mat-header-cell *matHeaderCellDef>Start</th>

                <td mat-cell *matCellDef="let override">{{ override.starttime | date:'short' }} </td>

            </ng-container>

            <ng-container matColumnDef="stoptime">

                <th mat-header-cell *matHeaderCellDef>Stop</th>

                <td mat-cell *matCellDef="let override">{{override.stoptime | date:'short'}}</td>

            </ng-container>

            <ng-container matColumnDef="metertypecode">

                <th mat-header-cell *matHeaderCellDef>Meter Type</th>

                <td mat-cell *matCellDef="let override">{{override.metertypecode}}</td>

            </ng-container>

            <ng-container matColumnDef="ldccode">

                <th mat-header-cell *matHeaderCellDef>LDC</th>

                <td mat-cell *matCellDef="let override">{{override.ldccode}}</td>

            </ng-container>

            <ng-container matColumnDef="electricldccode">

                <th mat-header-cell *matHeaderCellDef>Electric LDC</th>

                <td mat-cell *matCellDef="let override">{{override.electricldccode}}</td>

            </ng-container>

            <ng-container matColumnDef="retailercode">

                <th mat-header-cell *matHeaderCellDef>Retailer</th>

                <td mat-cell *matCellDef="let override">{{override.retailercode}}</td>

            </ng-container>

            <ng-container matColumnDef="profilecode">

                <th mat-header-cell *matHeaderCellDef>Profile</th>

                <td mat-cell *matCellDef="let override">{{override.profilecode}}</td>

            </ng-container>

            <ng-container matColumnDef="supplyclasscode">

                <th mat-header-cell *matHeaderCellDef>Suppy Class</th>

                <td mat-cell *matCellDef="let override">{{override.supplyclasscode}}</td>

            </ng-container>

            <ng-container matColumnDef="supplierproductcode">

                <th mat-header-cell *matHeaderCellDef>Product</th>

                <td mat-cell *matCellDef="let override">{{override.supplierproductcode}}</td>

            </ng-container>

            <ng-container matColumnDef="cpnodecode">

                <th mat-header-cell *matHeaderCellDef>CPN Node</th>

                <td mat-cell *matCellDef="let override">{{override.cpnodecode}}</td>

            </ng-container>

            <ng-container matColumnDef="locationcode">

                <th mat-header-cell *matHeaderCellDef>Location</th>

                <td mat-cell *matCellDef="let override">{{override.locationcode}}</td>

            </ng-container>

            <ng-container matColumnDef="zipcode">

                <th mat-header-cell *matHeaderCellDef>ZIP</th>

                <td mat-cell *matCellDef="let override">{{override.zipcode}}-{{override.plus4}}</td>

            </ng-container>

            <ng-container matColumnDef="naicscode">

                <th mat-header-cell *matHeaderCellDef>NAICS</th>

                <td mat-cell *matCellDef="let override">{{override.naicscode}}</td>

            </ng-container>

            <ng-container matColumnDef="weathersensitive">

                <th mat-header-cell *matHeaderCellDef>Weather Sensitive</th>

                <td mat-cell *matCellDef="let override">{{override.weathersensitive}}</td>

            </ng-container>

            <ng-container matColumnDef="bmg">

                <th mat-header-cell *matHeaderCellDef>BMG</th>

                <td mat-cell *matCellDef="let override">{{override.bmg}}</td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>

            <tr mat-row *matRowDef="let override;columns:displayedColumns"></tr>

        </table>
          
        <mat-paginator class="mat-elevation-z0"
            [length]="total"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [showFirstLastButtons]="showFirstLastButtons"            
            [pageIndex]="pageIndex">
        </mat-paginator>
        <!-- <div class="table-options">
            <mat-form-field>
              <mat-label>Go to page</mat-label>
                <mat-select [(ngModel)]="pageIndex" name="pageIndex">
                    <mat-option *ngFor="let pageIndex of pageOptions" [value]="pageIndex">{{pageIndex}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
         -->
    </div>
        
</div>