import {
  SplunkLoggerService,
  SplunkBodyInformation,
  SplunkEventInformation,
} from './splunk-api.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SplunkManager {
  constructor(private splunkAPIService: SplunkLoggerService) {}

  logSplunk(
    level: string,
    sourcetype: string,
    message: { flowName: any; flowDetails: any },
    elapsed?: number,
    method?: string,
    apiName?: string,
    splunkDetails?: any,
    userName?: any
  ) {
    const splunkInfo = {
      env: splunkDetails.env || '',
      portalName: splunkDetails.portalName || '',
      splunkToken: splunkDetails.splunkToken || '',
    };
    const splunkBody = this.buildSplunkBody(
      level,
      sourcetype,
      message,
      elapsed,
      method,
      apiName,
      userName,
      splunkInfo.portalName
    );

    return this.splunkAPIService.logSplunk(splunkBody, splunkInfo).pipe(
      catchError((err: any) => {
        console.log('error', err);

        return throwError(() => err);
      })
    );

    /*istanbul ignore next*/
    return of({});
  }

  /*istanbul ignore next*/
  getUserName(userName: any) {
    const anonymous = 'Anonymous';

    if (!_.isNil(userName)) {
      return userName;
    } else {
      return anonymous;
    }
  }

  /*istanbul ignore next*/
  getTimeStamp() {
    const d = new Date();
    const timeStamp =
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + (d.getDate() + 1)).slice(-2) +
      '/' +
      d.getFullYear() +
      ' ' +
      ('0' + d.getHours()).slice(-2) +
      ':' +
      ('0' + d.getMinutes()).slice(-2) +
      ':' +
      ('0' + d.getSeconds()).slice(-2);

    return timeStamp;
  }

  buildSplunkBody(
    level: string,
    sourcetype: string,
    message: any,
    elapsed?: number,
    method?: string,
    apiName?: string,
    userName?: string,
    portalName?: string
  ) {
    const emailAddress = this.getUserName(userName);
    const timeStamp = this.getTimeStamp();
    const splunkBodyInfo = new SplunkBodyInformation();
    splunkBodyInfo.event = new SplunkEventInformation();
    splunkBodyInfo.sourcetype = sourcetype;
    splunkBodyInfo.event.timestamp = timeStamp;
    splunkBodyInfo.event.userName = emailAddress;
    splunkBodyInfo.event.level = level;
    splunkBodyInfo.event.message = message;
    splunkBodyInfo.event.elapsed = elapsed;
    splunkBodyInfo.event.method = method;
    splunkBodyInfo.event.apiName = apiName;
    splunkBodyInfo.event.portalName = portalName;

    return splunkBodyInfo;
  }
}
