import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicePoint } from 'src/app/shared/models/servicePoint';

@Component({
  selector: 'app-edit-service-point',
  templateUrl: './service-point-edit.component.html',
  styleUrls: ['./service-point-edit.component.scss']
})
export class ServicePointEditComponent implements OnInit {

  editForm = new FormGroup({
    servicePointID: new FormControl({value: this.data.service_point_id, disabled: this.data?.service_point_id !== null}),
    effectiveDate: new FormControl(this.data.effective_date),
    terminationDate: new FormControl(this.data.termination_date),
    ldcCode: new FormControl(this.data.ldc_code),
    eldcCode: new FormControl(this.data.eldc_code),
    retailerCode: new FormControl(this.data.retailer_code),
    profileClassCode: new FormControl(this.data.profile_class_code),
    lossClassCode: new FormControl(this.data.loss_class_code),
    supplyClassCode: new FormControl(this.data.supply_class_code),
    meterTypeCode: new FormControl(this.data.meter_type_code),
    spaOverrideFlag: new FormControl(this.data.spa_override_flag)
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data: ServicePoint) { 
  }

  ngOnInit(): void {
  }

  updateServicePoint() {
    console.log(`Service Point Updated: ${JSON.stringify(this.data)}`);
  }
}
