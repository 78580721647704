<div class="coincident-peak">
    <h3>{{ title }}</h3>
    <form *ngIf="form" [formGroup]="form">
        <table class="cp-data">
            <tr>
                <th>
                    Season
                </th>
                <th>
                    Date
                </th>
                <th>
                    Audit Details
                </th>
            </tr>
            <tr>
                <td>
                    <p>
                        Summer
                    </p>
                    <p class="date-hint">
                        {{ coincidentPeakData ? formatSeasonDate(coincidentPeakData.summer) : "" }} 
                    </p>
                </td>
                <td> 
                    <mat-form-field>
                        <input matInput formControlName="summer">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                    </mat-form-field>
                </td>
                <td>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.summer.last_updated_by : "" }}
                    </p>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.summer.last_update_time : "" }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        Fall
                    </p>
                    <p class="date-hint">
                        {{ coincidentPeakData ? formatSeasonDate(coincidentPeakData.fall) : "" }}                     
                    </p>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput formControlName="fall">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                    </mat-form-field>
                </td>
                <td>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.fall.last_updated_by : "" }}
                    </p>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.fall.last_update_time : "" }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        Winter
                    </p>
                    <p class="date-hint">
                        {{ coincidentPeakData ? formatSeasonDate(coincidentPeakData.winter) : "" }}
                    </p>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput formControlName="winter">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                    </mat-form-field>
                </td>
                <td>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.winter.last_updated_by : "" }}
                    </p>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.winter.last_update_time : "" }}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>
                        Spring
                    </p>
                    <p class="date-hint">
                        {{ coincidentPeakData ? formatSeasonDate(coincidentPeakData.spring) : "" }}
                    </p>
                </td>
                <td>
                    <mat-form-field>
                        <input matInput formControlName="spring">
                        <mat-hint>MM/DD/YYYY hh:mm:ss</mat-hint>
                    </mat-form-field>
                </td>
                <td>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.spring.last_updated_by : "" }}
                    </p>
                    <p>
                        {{ coincidentPeakData ? coincidentPeakData.spring.last_update_time : "" }}
                    </p>
                </td>
            </tr>
        </table>
    </form>
</div>
