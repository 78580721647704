import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScalarProfilingService {

  constructor(private http: HttpClient) { }

  runScalarProfiling(
    operatingDay: string,
    servicePointId?: number | null,
    profileClassCode?: string | null

  ): Observable<string> {
    let params = new HttpParams();

    params = params.set('operating_day', operatingDay);
    
    if (servicePointId) {
      params = params.set('service_point_id', servicePointId);
    }
    
    if (profileClassCode) {
      params = params.set('profile_class_code', profileClassCode);
    }

    const requestOptions = { params: params };

    return this.http.get<string>('scalar_profiling', requestOptions )
    .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('There is an issue with the client or network:', error.error);
    } else {
      console.error('Server-side error: ', error.error)
    }

    return throwError(() => new Error('Cannot retrieve scalar usage data from the server. Please try again.'));
  }
}