import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { SpaOverride } from 'src/app/shared/models/spaOverride';
import { SpaOverrideService } from './service/spa-override.service';
import { MatSort } from '@angular/material/sort';
import { merge, tap } from 'rxjs';

@Component({
  selector: 'app-spa-overrides',
  templateUrl: './spa-overrides.component.html',
  styleUrls: ['./spa-overrides.component.scss']
})
export class SpaOverridesComponent implements AfterViewInit, OnInit  {
  overrides = new MatTableDataSource<SpaOverride>();
  
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions = [10, 25, 50];
  pageCount: number;
  showFirstLastButtons = true;
  total: number;
  pageOptions: number[] = [];
  @Output() page = new EventEmitter<PageEvent>();

  filters: Map<string, any> = new Map();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  @ViewChild(MatSort)
  sort: MatSort;

  filterForm = new UntypedFormGroup({
    startTimeFilter: new UntypedFormControl(''),
    endTimeFilter: new UntypedFormControl('')
  });

  constructor(private service: SpaOverrideService) { }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadOverridesPage())
      )
      .subscribe();
  }

  displayedColumns = [ 
    "uidservicepoint",
    "starttime", 
    "stoptime", 
    "metertypecode", 
    "ldccode", 
    "electricldccode", 
    "retailercode", 
    "profilecode", 
    "supplyclasscode", 
    "cpnodecode"
  ];

  ngOnInit() {
    this.overrides.paginator = this.paginator;
    this.loadOverridesPage();
  }

  addFilter(field: string, value: string) {
    this.filters.set(field, value);
  }

  applyFilters() {
    let starttime = (this.filterForm.get('startTimeFilter')?.value as Date);
    if (starttime) {
      this.addFilter('starttime', `>${starttime.toISOString().substring(0, 10)}`);
    }

    let endtime = (this.filterForm.get('endTimeFilter')?.value as Date);
    if (endtime) {
      if (starttime && starttime > endtime) {
        alert('Start Time must be later than End Time');
        this.filters.clear();
        return;
      }
      this.addFilter('endtime', `>${endtime.toISOString().substring(0, 10)}`);
    }

    this.loadOverridesPage();
  }

  loadOverridesPage(): void {

    try {        
      this.service.getOverrides(
        this.paginator?.pageIndex ?? 0,
        this.paginator?.pageSize ?? this.pageSize,
        this.filters).subscribe(
          (data : any) => {
            if (data.total === 0) {
              alert("No Overrides to display, try updating your filters")
            }
  
            this.overrides = data.records;
            this.total = data.total;
            this.pageCount = Math.ceil(this.total / this.pageSize);
            this.pageOptions = Array(this.pageCount).fill(1).map((x, i)=>i + 1);
          }
      );  

    } catch (error : any) {
      
      alert(error.message);

    }
  }
}
