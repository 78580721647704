import { Component, Input } from '@angular/core';
import {
  ColDef, 
  RowModelType,
  GridReadyEvent,
  IRowNode,
  IDateFilterParams,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  GridApi,
} from 'ag-grid-community';
import { ScalarUsage } from 'src/app/shared/models/scalarUsage';
import { ScalarUsageService } from './service/scalar-usage.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-scalar-usage',
  templateUrl: './scalar-usage.component.html',
  styleUrl: './scalar-usage.component.scss'
})
export class ScalarUsageComponent {
  @Input() servicePointId = ""; 

  private gridApi: GridApi;

  public rowData!: ScalarUsage[];

  colDefs: ColDef[] = [
    { 
      field: "start_time", 
      headerName: "Start Time",
      valueFormatter: p => 
        this.dateFormatter.transform(p.value, 'short')!, 
      flex: 1,
      sortable: true,
      sort: "asc"
    },
    { 
      field: "stop_time", 
      headerName: "Stop Time",
      valueFormatter: p => 
        this.dateFormatter.transform(p.value, 'short')!, 
      flex: 1,
      sortable: true
    },
    { 
      field: "kwh", 
      headerName: "Kilowatt-Hours", 
      flex: 1,
      sortable: true 
    },
    { 
      field: "add_time", 
      headerName: "Add Time",
      valueFormatter: p => 
        this.dateFormatter.transform(p.value, 'short')!, 
      flex: 1,
      sortable: true 
    },
    { 
      field: "measurement_type_code", 
      headerName: "Measurement Type", 
      flex: 1,
      sortable: true 
    }
  ];

  startTime: Date;
  stopTime: Date;

  public rowModelType: RowModelType = "clientSide";
  public paginationPageSize = 20;
  public themeClass: string = "ag-theme-material";  

  constructor(private service: ScalarUsageService, private dateFormatter: DatePipe) { }  

  ngAfterViewInit(): void {
  }

  onGridReady(params: GridReadyEvent<ScalarUsage>) {
    this.gridApi = params.api;
    this.loadScalarUsageData();  
  }

  ngOnInit(): void {
  }

  loadScalarUsageData(): void {

    try {        
      this.service.getScalarUsage(
        0, 1000, this.servicePointId).subscribe(
          (data : any) => {
            if (data.total === 0) {
              alert("No scalar usage data to display")
            }
            this.rowData = data.results;
          }
      );

    } catch (error : any) {
      
      alert(error.message);

    }
  }

}

var dateFilterParams: IDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var cellDate = asDate(cellValue);

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

function asDate(dateAsString: string) {
  var splitFields = dateAsString.split("/");
  return new Date(
    Number.parseInt(splitFields[2]),
    Number.parseInt(splitFields[1]) - 1,
    Number.parseInt(splitFields[0]),
  );
}