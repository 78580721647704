import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ServicePoint } from 'src/app/shared/models/servicePoint';
import { MatDialog } from '@angular/material/dialog';
import {
  ColDef, 
  RowModelType,
  GridReadyEvent,
  SizeColumnsToContentStrategy,
  GridApi,
  SelectionChangedEvent,
} from 'ag-grid-community'; // Column Definition Type Interface
import { ServicePointService } from './service/service-points.service';
import { ServicePointEditComponent } from '../service-point-edit/service-point-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-service-points',
  templateUrl: './service-point-list.component.html',
  styleUrls: ['./service-point-list.component.scss']
})
export class ServicePointListComponent {
  private referenceDate: string | null;
  private gridApi: GridApi;

  public defaultColDef: ColDef = {
    minWidth: 100,
    sortable: true,
  };

  colDefs: ColDef[] = [
    { field: "service_point_id", headerName: "Service Point ID", filter: true },
    { 
      field: "effective_date", 
      headerName: "Effective Date",
      valueFormatter: p => 
        this.dateFormatter.transform(p.value, 'short')!
     },
    { 
      field: "termination_date",
      valueGetter: function(params) {
        if (params.data.termination_date !== '9999-01-01T06:00:00')
          return params.data.termination_date
        else
          return "None"
      },
      headerName: "Termination Date",
      valueFormatter: p => {
        if (p.value != "None") {
          return this.dateFormatter.transform(p.value, 'short')!
        }
        else return "None";
      }
    },
    { field: "ldc_code", headerName: "LDC Code" },
    { field: "eldc_code", headerName: "ELDC Code" },
    { field: "retailer_code", headerName: "Retailer Code" },
    { field: "profile_class_code", headerName: "Profile Class Code" },
    { field: "loss_class_code", headerName: "Loss Class Code" },
    { field: "supply_class_code", headerName: "Supply Class Code" },
    { field: "meter_type_code", headerName: "Meter Type Code" },
    { field: "spa_override_flag", cellDataType: 'boolean', headerName: "Override Flag" }
  ];

  public rowModelType: RowModelType = "clientSide";
  public rowSelection: "single" | "multiple" = "single";
  public paginationPageSize = 20;
  public themeClass: string = "ag-theme-material";
  public autoSizeStrategy:
    SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };

  public rowData!: ServicePoint[];

  filters: Map<string, any> = new Map();

  filterForm = new UntypedFormGroup({
    effectiveDateFilter: new UntypedFormControl('')
  });

  constructor(
    private service: ServicePointService, 
    public dialog: MatDialog, 
    private route: ActivatedRoute,
    private router: Router,
    private dateFormatter: DatePipe
    ) { }  

  ngAfterViewInit(): void {
  }

  onGridReady(params: GridReadyEvent<ServicePoint>) {
    this.gridApi = params.api;
    this.loadServicePoints();  
  }

  ngOnInit(): void {
    this.referenceDate = this.route.snapshot.queryParamMap.get('reference_date');
  }

  loadServicePoints() {  
    this.service.getServicePoints(0, 2000, this.referenceDate).subscribe(

      (data : any) => {
        if (data.total === 0) {
          alert("No service points to display, try updating your filters")
        }
        this.rowData = data.results;
      }
    );
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const selectedData = this.gridApi.getSelectedRows();
    this.detail(selectedData[0]);
  }

  editRow(servicePoint: ServicePoint) {
    this.dialog.open(ServicePointEditComponent, {
      data: servicePoint,
    });
  }

  detail(service_point: ServicePoint) {
    this.router.navigate(['/service-point'], { queryParams : { reference_date: this.referenceDate, service_point_id: service_point.service_point_id }  });
  }
}
