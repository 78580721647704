import { CoincidentPeak } from "./coincidentPeak";
import { MisoCoincidentPeak } from "./coincidentPeak";
import { NonCoincidentPeak } from "./coincidentPeak";

export interface PlanningData {
    fall: CoincidentPeak;
    winter: CoincidentPeak;
    spring: CoincidentPeak;
    summer: CoincidentPeak;
}

export class MisoCPPlanningData implements PlanningData {
    fall: MisoCoincidentPeak;
    winter: MisoCoincidentPeak;
    spring: MisoCoincidentPeak;
    summer: MisoCoincidentPeak;
}

export class NonCPPlanningData implements PlanningData {
    fall: NonCoincidentPeak;
    winter: NonCoincidentPeak;
    spring: NonCoincidentPeak;
    summer: NonCoincidentPeak;
}