import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { ProfileManagerService } from './profilemanager.service';
import { Profile } from '../models/profile';

export function getSettings(): UserManagerSettings {
    return {
        authority: environment.authority,
        client_id: environment.client_id,
        redirect_uri: `${window.location.origin}/signincallback`,
        post_logout_redirect_uri: `${window.location.origin}/signoutcallback`,
        response_type: "code",
        scope: environment.scope,
        filterProtocolClaims: true,
        loadUserInfo: true,
        automaticSilentRenew: true,
        acr_values: 'eapotplogin',
        accessTokenExpiringNotificationTime: 120,
        silent_redirect_uri: `${window.location.origin}/silentcallback`,
        includeIdTokenInSilentRenew: false,
        silentRequestTimeout: 120000
    };
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _userManager = new UserManager(getSettings());
    private _user: User | null;
    private _loginChangedSubject = new Subject<boolean>();
    private _userLoadedSubject = new Subject<User | null>();
    _profile: any;

    loginChanged = this._loginChangedSubject.asObservable();
    userLoaded = this._userLoadedSubject.asObservable();

    constructor(private profileManager: ProfileManagerService) {
        this._userManager.events.addSilentRenewError(() => {
            this.Logout();
        });

        this._userManager.events.addUserSignedOut(() => {
            this.Logout();
        });

        this._userManager.events.addUserLoaded((u) => {
            this._userLoadedSubject.next(u);
        });
    }

    Login(): Promise<any> {
        return this._userManager.signinRedirect().catch(err => {
            console.log('Login Error:', err);
        })
    }

    Logout(): Promise<any> {

        return this._userManager.signoutRedirect();
    }

    isLoggedIn(): Promise<boolean> {
        return this._userManager.getUser().then((user) => {
            this.refreshUserData(user);
            const userCurrent = !!user && !user.expired;
            if (this._user !== user) {
                this._loginChangedSubject.next(userCurrent);
            }
            this._user = user;
            return userCurrent;
        });
    }

    refreshUserData(currentUser: any) {
        this._profile = [];
        if (currentUser) {
            this._profile.push(new Profile(currentUser.profile.firstname, currentUser.profile.telephoneNumber, currentUser.profile.auditTrackingId, currentUser.profile.isverified, currentUser.profile.agencyRoleMap, currentUser.profile.azp, currentUser.profile.email, currentUser.profile.isMigrated, currentUser.profile.stateProvince, currentUser.profile.lastname, currentUser.profile.lastLoginTime, currentUser.profile.isOtpEnabled, currentUser.profile.pwdChangedTime));
            this.profileManager.profile = this._profile;
        }
    }

    getUser(): Promise<User | null> {
        return this._userManager.getUser();
    }

    LoginComplete() {
        return this._userManager.signinRedirectCallback().then((user) => {
            this._user = user;
            this._loginChangedSubject.next(!!user && !user.expired);
            return user;
        });
    }

    LogOutComplete() {
        this._user = null;
        this._userLoadedSubject.next(null);
        this._loginChangedSubject.next(false);
        this.profileManager.resetProfileManager();
        return this._userManager.signoutRedirectCallback().then(() => {
            this._userManager.removeUser();
        })
            .catch((error) => this.handleError(error));
    }

    handleError(error: any) {
        console.log('Problem with ending the session: ', error);
    }

    getAccessToken() {
        let currentUser;
        return this._userManager.getUser().then(user => {
            currentUser = user;
            return currentUser?.access_token;
        })
    }

    resetUser() {
        this._user = null;
        this._userLoadedSubject.next(null);
        this._loginChangedSubject.next(false);
        this.profileManager.resetProfileManager();
        return this._userManager.signoutRedirectCallback().then(() => {
            this._userManager.removeUser();
        })
            .catch((error) => this.handleError(error));
    }

}
