import { Input, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AmerenCarouselSlide } from '../../interface/ameren-carousel-slide.interface';

@Component({
  selector: 'ameren-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnChanges {
  constructor() { }

  @Input() slides: AmerenCarouselSlide[];
  @Input() slidesPerPage: number = 1;
  @Input() autoSlidingDuration: number = 5;
  @Input() disableAutoSliding: boolean = false;
  @Input() slideClass: string;
  @Input() enableFixedColumn: boolean = false;

  numbersArray: number[] = [];
  selectedPosition = 0;
  currentDisplay = {
    start: 0,
    end: -1
  };
  fillColumn = false;
  fillColumnArray: number[];

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentDisplay = {
      start: 0,
      end: this.slidesPerPage - 1
    };

    this.fillColumn = false;

    this.numbersArray = Array(
      Math.round(this.slides.length / this.slidesPerPage)
    )
      .fill(this.slidesPerPage)
      .map((x, i) => i);

    if (!this.disableAutoSliding)
      setInterval(() => {
        this.onCircleClick(
          this.selectedPosition < this.numbersArray.length - 1
            ? this.selectedPosition + 1
            : 0
        );
      }, this.autoSlidingDuration * 1000);
  }

  onPreviousClick() {
    if (this.currentDisplay.start <= 0) {
      this.onCircleClick(this.numbersArray.length - 1);
    } else this.onCircleClick(this.selectedPosition - 1);
  }

  onNextClick() {
    if (this.currentDisplay.end >= this.slides.length - 1) {
      this.onCircleClick(0);
    } else {
      this.onCircleClick(this.selectedPosition + 1);
    }
  }

  onCircleClick(position: number) {
    this.selectedPosition = position;
    this.currentDisplay = {
      start: position * this.slidesPerPage,
      end:
        position == 0
          ? this.slidesPerPage - 1
          : position * this.slidesPerPage + this.slidesPerPage - 1
    };
    this.fillColumn = this.enableFixedColumn && (this.currentDisplay.end + 1) > this.slides.length ? true : false;
    if (this.fillColumn) {
      this.fillColumnArray = Array(this.currentDisplay.end + 1 - this.slides.length).fill(0);
    }
  }
}
