<div class="service-point-details">
    <h3 style="text-align:left; font-weight: bold;">Details for service point ID {{ servicePoint?.service_point_id }} as of {{referenceDate}}</h3>
    <table class="service-point-detail">
        <tr>
            <td class="field-label">
                Service Point ID:
            </td>
            <td class="field-value">
                {{ servicePoint?.service_point_id }}
            </td>
            <td class="field-label">
                Effective Date:
            </td>
            <td class="field-value">
                {{ servicePoint?.effective_date | date:'short' }}
            </td>
            <td class="field-label">
                Termination Date:
            </td>
            <td class="field-value">
                {{ servicePoint?.termination_date | date:'short' }}
            </td>
        </tr>
        <tr>
            <td class="field-label">
                LDC Code:
            </td>
            <td class="field-value">
                {{ servicePoint?.ldc_code }}
            </td>
            <td class="field-label">
                ELDC Code:
            </td>
            <td class="field-value">
                {{ servicePoint?.eldc_code }}
            </td>
            <td class="field-label">
                Retailer Code:
            </td>
            <td class="field-value">
                {{ servicePoint?.retailer_code }}
            </td>
        </tr>
        <tr>
            <td class="field-label">
                Loss Class Code:
            </td>
            <td class="field-value">
                {{ servicePoint?.loss_class_code }}
            </td>
            <td class="field-label">
                Supply Class Code:
            </td>
            <td class="field-value">
                {{ servicePoint?.supply_class_code }}
            </td>
            <td class="field-label">
                Meter Type Code:
            </td>
            <td class="field-value">
                {{ servicePoint?.meter_type_code }}
            </td>
        </tr>
        <tr>
            <td class="field-label">
                Override: 
            </td>
            <td class="field-value">
                <mat-checkbox [checked]="servicePoint?.spa_override_flag"></mat-checkbox>
            </td>
            <td class="field-label">
                Profile Class Code:
            </td>
            <td class="field-value">
                <button 
                    mat-raised-button 
                    (click)="profileSegment()"
                    matTooltip="View Profile Segment Data"
                >{{ servicePoint?.profile_class_code }}</button>
            </td>
            <td></td>
        </tr>
    </table>

</div>