import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  ColDef, 
  RowModelType,
  GridReadyEvent,
  SizeColumnsToContentStrategy,
  GridApi
} from 'ag-grid-community'; 
import { ProfileClass } from 'src/app/shared/models/profileClass';
import { ProfileSegmentService } from './service/profile-segment.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile-segment',
  templateUrl: './profile-segment.component.html',
  styleUrl: './profile-segment.component.scss'
})
export class ProfileSegmentComponent {
  public profileClassCode: string;
  
  private gridApi: GridApi;

  public defaultColDef: ColDef = {
    minWidth: 100,
    sortable: true,
  };

  colDefs: ColDef[] = [
    { field: "profile_class_code", headerName: "Profile Class Code", sortable: true },
    { field: "market_hour", headerName: "Market Hour", sort: "asc", sortable: true },
    { field: "kwh", headerName: "Kilowatt-Hour", sortable: true },
    { field: "measurement_value_type_code", headerName: "Measurment Value Type Code", sortable: true },
    { field: "add_time", headerName: "Add Time", sortable: true },
    { field: "measurement_type_code", headerName: "Measurment Type Code", sortable: true }
  ];

  public rowModelType: RowModelType = "clientSide";
  public rowSelection: "single" | "multiple" = "single";
  public paginationPageSize = 20;
  public themeClass: string = "ag-theme-material";
  public autoSizeStrategy:
    SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };

  public rowData!: ProfileClass[];

  filters: Map<string, any> = new Map();

  filterForm = new UntypedFormGroup({
    effectiveDateFilter: new UntypedFormControl('')
  });

  constructor(
    private service: ProfileSegmentService,
    private route: ActivatedRoute
    ) { }  

  ngAfterViewInit(): void {
  }

  onGridReady(params: GridReadyEvent<ProfileClass>) {
    this.gridApi = params.api;
    this.loadServicePoints();  
  }

  ngOnInit(): void {
    this.profileClassCode = this.route.snapshot.queryParamMap.get('profile_class_code')!;
  }

  loadServicePoints() {  
    this.service.getProfileSegmentData(0, 2000, this.profileClassCode).subscribe(

      (data : any) => {
        if (data.total === 0) {
          alert("No profile segment data to display")
        }
        this.rowData = data.results;
      }
    );
  }
}
