<h1 mat-dialog-title>Edit Service Point</h1>
<div mat-dialog-content><!-- <p>{{servicePoint?.servicepointuid}}</p> -->
    <form
        class="edit-form"
        [formGroup]="editForm"
        (ngSubmit)="$event.preventDefault(); updateServicePoint();"
    >
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Service Point ID</mat-label>
            <input 
                matInput
                formControlName="servicePointID"
            >
        </mat-form-field>
        <table>
            <tr>
                <mat-form-field>
                    <mat-label>Effective Date</mat-label>
                    <input 
                        matInput
                        formControlName="effectiveDate"
                        [matDatepicker]="effectiveDate"
                    >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="effectiveDate"></mat-datepicker-toggle>
                    <mat-datepicker #effectiveDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Termination Date</mat-label>
                    <input 
                        matInput
                        formControlName="terminationDate"
                        [matDatepicker]="terminationDate"
                    >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="terminationDate"></mat-datepicker-toggle>
                    <mat-datepicker #terminationDate></mat-datepicker>
                </mat-form-field>
            </tr>
            <tr>
                <mat-form-field>
                    <mat-label>LDC Code</mat-label>
                    <input 
                        matInput 
                        formControlName="ldcCode"
                    >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>ELDC Code</mat-label>
                    <input 
                        matInput 
                        formControlName="eldcCode"
                    >
                </mat-form-field>
            </tr>
            <tr>                
                <mat-form-field>
                    <mat-label>Retailer Code</mat-label>
                    <input 
                        matInput 
                        formControlName="retailerCode"
                    >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Loss Class</mat-label>
                    <input 
                        matInput 
                        formControlName="lossClassCode"
                    >
                </mat-form-field>
            </tr>
            <tr>                
                <mat-form-field>
                    <mat-label>Profile Class</mat-label>
                    <input 
                        matInput 
                        formControlName="profileClassCode"
                    >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Supply Class</mat-label>
                    <input 
                        matInput 
                        formControlName="supplyClassCode"
                    >
                </mat-form-field>
            </tr>
            <tr>                
                <mat-form-field>
                    <mat-label>Meter Type</mat-label>
                    <input 
                        matInput 
                        formControlName="meterTypeCode"
                    >
                </mat-form-field>
            </tr>
            <tr>
                <mat-label>Override: </mat-label>
                <mat-checkbox
                    matInput
                    style="text-align: left;"
                    formControlName="spaOverrideFlag"
                ></mat-checkbox>
            </tr>
        </table>
        <div mat-dialog-actions>
            <button 
                mat-dialog-close
                mat-raised-button
                color="primary" 
                type="submit"
            >Save</button>
            <button mat-raised-button mat-dialog-close>Close</button>
        </div>
    </form>
</div>
