import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
        'expand-more',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/expand-more.svg'));
    iconRegistry.addSvgIcon(
        'search',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/search.svg'));
    iconRegistry.addSvgIcon(
        'settings',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/settings.svg'));
    iconRegistry.addSvgIcon(
        'bolt',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/bolt.svg'));
    iconRegistry.addSvgIcon(
        'logout',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/logout.svg'));
    iconRegistry.addSvgIcon(
        'database',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/database.svg'));
    iconRegistry.addSvgIcon(
        'monitoring',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icon/monitoring.svg'));
}


  ngOnInit(): void {
  }

}
