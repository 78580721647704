<div class="planning-year">
    <h2>Annual Planning Data</h2>
    <mat-form-field>
        <mat-label>Planning Year</mat-label>
        <mat-select 
            [(ngModel)]="planningYear"
            matInput
            (ngModelChange)="setPlanningYear()"
            [disabled]="isYearSet()"
        >
            <mat-option *ngFor="let year of planningYearOptions" [value]="year.value">{{ year.description }}</mat-option>
        </mat-select>
    </mat-form-field>
    <form [formGroup]="planningForm" (ngSubmit)="createUpdateData()">
        <div [hidden]="!isYearSet()">
            <table class="planning-data" >
                <tr>
                    <td>
                        <coincident-peak title="MISO Coincident Peak" [coincidentPeakData]="misoCPViewData" formGroupName="misoCPForm">
                        </coincident-peak>
                    </td>
                    <td>
                        <coincident-peak title="Ameren Non-Coincident Peak" [coincidentPeakData]="nonCPViewData" formGroupName="nonCPForm"></coincident-peak>
                    </td>
                </tr>
                <tr>
                    <td>
                    </td>
                    <td>
                    </td>
                </tr>
            </table>
            <div class="buttons">
                <button type="submit" mat-flat-button color="primary" [disabled]="!formIsDirty()">
                    Save
                </button>
                <button type="button" (click)="clearForm()" mat-stroked-button>
                    Cancel
                </button>
            </div>
        </div>
    </form>
</div>
