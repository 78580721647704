import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';


export interface Filter {
  field: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  login() {
    const hostname = document.location.hostname;
    let baseUrl: string;

    switch(hostname) {
      case "localhost": {
          baseUrl = "https://settlement-dev-api.np.ce.ameren.com";
          break;
      }
      case "settlement-dev.np.ce.ameren.com": {
          baseUrl = "https://settlement-dev-api.np.ce.ameren.com";
          break;
      }
      case "settlement-uat.np.ce.ameren.com": {
          baseUrl = "https://settlement-uat-api.np.ce.ameren.com";
          break;
      }
      case "settlement-prod.np.ce.ameren.com": {
          baseUrl = "https://settlement-prod-api.np.ce.ameren.com";
          break;
      }
      default: {
          baseUrl = "https://settlement-dev-api.np.ce.ameren.com";
      }
    }
    
    let encodedURL: string = btoa(this.document.location.toString());

    document.location.href = `${baseUrl}/auth/login?next_url=${encodedURL}`;
  }
}