import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ameren-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();
  constructor() {}

  @Input() topLinks: any[] = [
    {
      href: 'https://www.ameren.com/company/about-ameren',
      text: 'About Ameren',
    },
    {
      href: 'https://www.ameren.com/company/media',
      text: 'Media',
    },
    {
      href: 'https://www.ameren.com/company/careers/diversity',
      text: 'Diversity',
    },
    {
      href: 'https://www.ameren.com/company/careers',
      text: 'Careers',
    },
    {
      href: 'https://www.ameren.com/contact-us',
      text: 'Contact Us',
    },
  ];
  @Input() bottomLinks: any[] = [
    {
      href: 'https://www.ameren.com/sitemap',
      text: 'Site Map',
    },
    {
      href: 'https://www.ameren.com/privacy',
      text: 'Privacy Policy',
    },
    {
      href: 'https://www.ameren.com/terms-and-conditions',
      text: 'Terms and Conditions',
    },
    {
      href: 'https://www.ameren.com/MyAmeren',
      text: 'Employee Login',
    },
  ];
  ngOnInit(): void {}
}
