import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { RegistrationManagerService } from 'src/app/public/registration/services/registration-manager.service';
import { states } from 'src/app/shared/constants/statesList';
import { formatDate } from '@angular/common';

declare var window: any;

@Component({
  selector: 'app-register-new-agency',
  templateUrl: './register-new-agency.component.html',
  styleUrls: ['./register-new-agency.component.scss'],
})
export class RegisterNewAgencyComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private registrationManagerService: RegistrationManagerService,
    private _utility: UtilitiesService
  ) {}
  registerForm: FormGroup;
  integrationErrorMsg: string;
  isAPIFailure: boolean = false;
  isLoading: boolean = false;
  states: any[];
  illinois = 18;
  missouri = 17;
  amerenState = 0;
  errorMessage = '';
  stateErrorMessage = '';
  isMissouri = false;
  isIllinois = false;
  disabled = true;
  authorizationModal: any;
  termsMOModal: any;
  termsILModal: any;

  ngOnInit(): void {
    this.initRegisterForm();
    this.authorizationModal = new window.bootstrap.Modal(
      document.getElementById('authorizationModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
    this.termsMOModal = new window.bootstrap.Modal(
      document.getElementById('termsMOModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
    this.termsILModal = new window.bootstrap.Modal(
      document.getElementById('termsILModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );
  }

  get representatives(): FormArray {
    return this.registerForm.get('representatives') as FormArray;
  }
  get zipcodes(): FormArray {
    return this.registerForm.get('zipcodes') as FormArray;
  }
  get referrals() {
    return this.registerForm.get('referrals')!;
  }
  onSameAsClick() {
    (this.registerForm.get('mailingAddress') as FormGroup).patchValue(
      this.registerForm.get('agencyAddress')?.value
    );
  }
  addrep() {
    (this.registerForm.get('representatives') as FormArray).push(
      this._fb.group({
        firstName: new FormControl(null, [
          Validators.pattern('^[-_ a-zA-Z]+$'),
        ]),
        lastName: new FormControl(null, [Validators.pattern('^[-_ a-zA-Z]+$')]),
      })
    );
  }
  deleterep(index: number) {
    (this.registerForm.get('representatives') as FormArray).removeAt(index);
  }
  addzip() {
    let referral = this.registerForm.get('zipcodes') as FormArray;
    let length = referral?.controls.length;
    if (
      referral &&
      length > 0 &&
      referral.controls[length - 1].get('zip')?.value
    ) {
      referral.push(
        this._fb.group({
          zip: new FormControl(null, [Validators.pattern('[0-9]{5}')]),
        })
      );
    }
  }

  initRegisterForm() {
    this.states = states;

    this.registerForm = this._fb.group({
      agencyName: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      requesterFirstName: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      requesterLastName: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      requesterTitle: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      emailAddress: new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      phoneNumber: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]{3}.[0-9]{3}.[0-9]{4}$'),
      ]),
      fax: new FormControl(null, [
        Validators.pattern('^[0-9]{3}.[0-9]{3}.[0-9]{4}$'),
      ]),
      agencyAddress: this._fb.group({
        addressLine1: new FormControl(null, [Validators.required]),
        addressLine2: new FormControl(),
        city: new FormControl(null, [
          Validators.required,
          Validators.pattern('^[-_ a-zA-Z0-9]+$'),
        ]),
        zip: new FormControl(null, [
          Validators.required,
          Validators.pattern('[0-9]{5}'),
        ]),
        state: new FormControl(null, [Validators.required]),
      }),
      mailingAddress: this._fb.group({
        addressLine1: new FormControl(null, [Validators.required]),
        addressLine2: new FormControl(),
        city: new FormControl(null, [
          Validators.required,
          Validators.pattern('^[-_ a-zA-Z0-9]+$'),
        ]),
        zip: new FormControl(null, [
          Validators.required,
          Validators.pattern('[0-9]{5}'),
        ]),
        state: new FormControl(null, [Validators.required]),
      }),
      representatives: this._fb.array([
        this._fb.group({
          firstName: new FormControl(null, [
            Validators.pattern('^[-_ a-zA-Z0-9]+$'),
          ]),
          lastName: new FormControl(null, [
            Validators.pattern('^[-_ a-zA-Z0-9]+$'),
          ]),
        }),
      ]),
      referrals: new FormControl(false),
      zipcodes: this._fb.array([
        this._fb.group({
          zip: new FormControl(null),
        }),
      ]),
      acceptAuthorizationAndConfStatements: new FormControl(false, [
        Validators.pattern('true'),
      ]),
      termcondition: new FormControl(false, [Validators.pattern('true')]),
    });

    this.requiredIfValidator();
  }

  onBackClick() {
    this._router.navigate(['/']);
  }
  submitAgencyRegistration() {
    this.isLoading = true;
    this.isAPIFailure = false;
    (this.registerForm.controls['term'] as FormGroup)?.controls[
      'termcondition'
    ].markAsDirty();
    let representativesValues: any[] = [];
    this.representatives.controls.forEach((element, index) => {
      representativesValues.push(
        this.representatives.controls[index].get('firstName')?.value +
          ' ' +
          this.representatives.controls[index].get('lastName')?.value
      );
    });

    if (!this.checkRepresentativeValues(representativesValues)) {
      representativesValues = [];
      representativesValues[0] =
        this.registerForm.controls['requesterFirstName'].value +
        ' ' +
        this.registerForm.controls['requesterLastName'].value;
    }

    let zipcodesValues: any[] = [];
    if (this.referrals) {
      this.zipcodes.controls.forEach((element, index) => {
        if (element.get('zip')?.value) {
          zipcodesValues.push(this.zipcodes.controls[index].get('zip')?.value);
        }
      });
    }

    if (this.amerenState == 0) {
      this.isLoading = true;
      this.errorMessage = 'Ameren state is required';
      return;
    }

    let trimphone = this.registerForm.controls['phoneNumber'].value
      ?.replace(/[^0-9 ]/g, '')
      .replace(/\s/g, '');
    let trimfax = this.registerForm.controls['fax'].value
      ?.replace(/[^0-9 ]/g, '')
      .replace(/\s/g, '');
    const body = {
      companyCode: this.amerenState,
      agencyName: this.registerForm.controls['agencyName'].value, //"New Registration Agency",
      phoneNumber: trimphone, // "3141231234",
      faxNumber: trimfax, // 1234567890,
      emailAddress: this.registerForm.controls['emailAddress'].value, // "test@none.org",
      requestorName:
        this.registerForm.controls['requesterFirstName'].value +
        ' ' +
        this.registerForm.controls['requesterLastName'].value, //"John Doe",
      requestorTitle: this.registerForm.controls['requesterTitle'].value, // "Agency Representative",
      agencyStreetAddress: {
        addressLine1: this.registerForm
          .get('agencyAddress')
          ?.get('addressLine1')?.value, // "123 Anywhere",
        addressLine2:
          this.registerForm.get('agencyAddress')?.get('addressLine2')?.value ==
          null
            ? ''
            : this.registerForm.get('agencyAddress')?.get('addressLine2')
                ?.value,
        addressLine3: '',
        city: this.registerForm.get('agencyAddress')?.get('city')?.value, //"St Louis",
        postalCode: this.registerForm.get('agencyAddress')?.get('zip')?.value, // "63101",
        state: this.registerForm.get('agencyAddress')?.get('state')?.value, // "MO",
        country: 'USA',
      },
      agencyMailingAddress: {
        addressLine1: this.registerForm
          .get('mailingAddress')
          ?.get('addressLine1')?.value, // "123 Anywhere",
        addressLine2:
          this.registerForm.get('mailingAddress')?.get('addressLine2')?.value ==
          null
            ? ''
            : this.registerForm.get('mailingAddress')?.get('addressLine2')
                ?.value, // "",
        addressLine3: '',
        city: this.registerForm.get('mailingAddress')?.get('city')?.value, // "St Louis",
        postalCode: this.registerForm.get('mailingAddress')?.get('zip')?.value, // "63101",
        state: this.registerForm.get('mailingAddress')?.get('state')?.value, // "MO",
        country: 'USA',
      },
      pdfImage: 'regTest03235.pdf',
      isCustomerReffered: this.registerForm.controls['referrals'].value,
      serviceZipCodes: zipcodesValues,
      agencyRepresentatives: representativesValues,
    };
    this.generateDataForPDF();

    this.registrationManagerService.submitAgencyRegistration(body).subscribe(
      (res) => {
        this.isLoading = false;
        if (!res.success) {
          this.isAPIFailure = true;
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        } else {
          this.isAPIFailure = false;
          this._router.navigateByUrl('/agency-registration-success');
        }
      },
      (error: AmerenError) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        if (error.status === 400) {
          this.integrationErrorMsg =
            'Sorry, something is not right. Please try again.';
        } else {
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        }
      }
    );
  }

  public generateDataForPDF(): void {
    let representativesValues: any[] = [];
    this.representatives.controls.forEach((element, index) => {
      representativesValues.push(
        this.representatives.controls[index].get('firstName')?.value +
          ' ' +
          this.representatives.controls[index].get('lastName')?.value
      );
    });

    if (!this.checkRepresentativeValues(representativesValues)) {
      representativesValues = [];
      representativesValues[0] =
        this.registerForm.controls['requesterFirstName'].value +
        ' ' +
        this.registerForm.controls['requesterLastName'].value;
    }

    let zipcodesValues: any[] = [];
    if (this.referrals) {
      this.zipcodes.controls.forEach((element, index) => {
        if (element.get('zip')?.value) {
          zipcodesValues.push(this.zipcodes.controls[index].get('zip')?.value);
        }
      });
    }

    const pdfData = {
      registrationID: 0,
      agencyPin: '',
      agencyName: this.registerForm.controls['agencyName'].value,
      companyCode: this.isMissouri ? 'MO' : 'IL',
      dateCreated: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      updatedBy: '',
      agencyStreetAddress: this.registerForm
        .get('agencyAddress')
        ?.get('addressLine1')?.value,
      agencyCity: this.registerForm.get('agencyAddress')?.get('city')?.value,
      agencyState: this.registerForm.get('agencyAddress')?.get('state')?.value,
      agencyZip: this.registerForm.get('agencyAddress')?.get('zip')?.value,
      registrationStatus: 'Pending',
      agencyEmailAddress: this.registerForm.controls['emailAddress'].value,
      phoneNumber:
        this.registerForm.controls['phoneNumber'].value
          ?.replace(/[^0-9 ]/g, '')
          .replace(/\s/g, '') || '',
      faxNumber:
        this.registerForm.controls['fax'].value
          ?.replace(/[^0-9 ]/g, '')
          .replace(/\s/g, '') || '',
      emailAddress: this.registerForm.controls['emailAddress'].value,
      requestorName:
        this.registerForm.controls['requesterFirstName'].value +
        ' ' +
        this.registerForm.controls['requesterLastName'].value,
      requestorTitle: this.registerForm.controls['requesterTitle'].value,
      dateUpdated: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      agencyStreetAddressAddressLine1: this.registerForm
        .get('agencyAddress')
        ?.get('addressLine1')?.value,
      agencyStreetAddressCity: this.registerForm
        .get('agencyAddress')
        ?.get('city')?.value,
      agencyStreetAddressPostalCode: this.registerForm
        .get('agencyAddress')
        ?.get('zip')?.value,
      agencyStreetAddressState: this.registerForm
        .get('agencyAddress')
        ?.get('state')?.value,
      agencyMailingAddressAddressLine1: this.registerForm
        .get('mailingAddress')
        ?.get('addressLine1')?.value,
      agencyMailingAddressCity: this.registerForm
        .get('mailingAddress')
        ?.get('city')?.value,
      agencyMailingAddressPostalCode: this.registerForm
        .get('mailingAddress')
        ?.get('zip')?.value,
      agencyMailingAddressState: this.registerForm
        .get('mailingAddress')
        ?.get('state')?.value,
      isCustomerReffered: this.registerForm.controls['referrals'].value
        ? 'Yes'
        : 'No',
      pdfImage: 'agency.pdf',
      serviceZipCodes: zipcodesValues,
      agencyRepresentatives: representativesValues,
    };

    this._utility.SetPdfObject(JSON.stringify(pdfData));
  }

  public verifyRecaptchaForAgencyRegistration(): void {
    this.isLoading = true;
  }

  handleToken(token: any) {
    const recaptchaBody = {
      recaptchaResponse: token,
    };

    this.registrationManagerService
      .recaptchaVerification(recaptchaBody)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          if (res.score > 0.5) {
            this.submitAgencyRegistration();
          } else {
            this.isAPIFailure = true;
            this.integrationErrorMsg =
              'Sorry, something went wrong. Please try again later.';
            this.isLoading = false;
          }
        },
        error: () => {
          this.isAPIFailure = true;
          this.isLoading = false;
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        },
      });
  }

  requiredIfValidator() {
    this.registerForm.controls['referrals'].valueChanges.subscribe((val) => {
      this.zipcodes.clear();

      if (val) {
        this.zipcodes.push(
          this._fb.group({
            zip: new FormControl(null, [
              Validators.required,
              Validators.pattern('[0-9]{5}'),
            ]),
          })
        );
      } else {
        this.zipcodes.push(
          this._fb.group({
            zip: new FormControl(null),
          })
        );
      }
    });
  }

  checkRepresentativeValues(representativesValues: any[]) {
    for (let repValue of representativesValues) {
      let repValueSplit = repValue.split(' ');
      if (
        repValueSplit[0] != 'null' &&
        repValueSplit[1] != 'null' &&
        repValueSplit[0] != '' &&
        repValueSplit[1] != ''
      ) {
        return true;
      }
    }
  }

  selectStateCode(event: any) {
    this.errorMessage = '';
    this.stateErrorMessage = '';
    if (event) {
      this.amerenState = this.missouri;
    } else {
      this.amerenState = this.illinois;
    }
  }

  activateMissouriButton(event: any) {
    this.isIllinois = false;
    this.isMissouri = true;
    this.disabled = false;
    this.selectStateCode(event);
  }

  activateIllinoisButton(event: any) {
    this.isIllinois = true;
    this.isMissouri = false;
    this.disabled = false;
    this.selectStateCode(event);
  }

  showAuthorizationModal() {
    this.authorizationModal.show();
  }

  showTermsModal() {
    if (this.amerenState == 0) {
      this.stateErrorMessage =
        'Please select an Ameren state above to view portal access terms and conditions';
    } else {
      if (this.isMissouri) this.termsMOModal.show();
      if (this.isIllinois) this.termsILModal.show();
    }
  }
}
