<h3 class="text-center page-header">New Agency Registration</h3>
<div class="new_agency_back_btn" (click)="onBackClick()">
  <svg
    width="22px"
    height="19px"
    viewBox="0 0 22 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>🎨 Icon Сolor</title>
    <g
      id="New-Login-/-Registration"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Register-Account---validation"
        transform="translate(-62.000000, -156.000000)"
        fill="#1B6CB5"
        fill-rule="nonzero"
      >
        <path
          d="M82.6428483,164.142787 L66.5402438,164.142787 L71.4707758,158.225605 C71.9512075,157.650173 71.8724927,156.79381 71.2970604,156.314736 C70.7202709,155.834304 69.8652654,155.913019 69.3861908,156.488451 L62.6004325,164.631361 C62.5475036,164.695147 62.5203605,164.769791 62.4810031,164.840363 C62.4484315,164.897363 62.4090741,164.94622 62.3846454,165.008649 C62.3235735,165.164722 62.2869304,165.328937 62.2869304,165.49451 C62.2869304,165.495867 62.2855733,165.498581 62.2855733,165.499938 C62.2855733,165.501295 62.2869304,165.50401 62.2869304,165.505367 C62.2869304,165.670939 62.3235735,165.835155 62.3846454,165.991227 C62.4090741,166.053656 62.4484315,166.102514 62.4810031,166.159514 C62.5203605,166.230086 62.5475036,166.304729 62.6004325,166.368515 L69.3861908,174.511425 C69.6549069,174.83307 70.0403379,175 70.4284833,175 C70.7351996,175 71.043273,174.896856 71.2970604,174.685141 C71.8724927,174.206066 71.9512075,173.349704 71.4707758,172.774271 L66.5402438,166.85709 L82.6428483,166.85709 C83.3919961,166.85709 84,166.249086 84,165.499938 C84,164.750791 83.3919961,164.142787 82.6428483,164.142787"
          id="🎨-Icon-Сolor"
        ></path>
      </g>
    </g>
  </svg>
  <span>Back</span>
</div>
<div class="col-md-6 new_agency_form">
  <form [formGroup]="registerForm">
    <section class="mb-5">
      <div class="row m-0">
        <h4 class="section-header">Personal Information</h4>
        <div class="form-group">
          <label class="required">Agency Name</label>
          <input
            formControlName="agencyName"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('agencyName')?.dirty &&
              registerForm?.get('agencyName')?.invalid
            "
          >
            <p *ngIf="registerForm?.get('agencyName')?.errors?.['required']">
              Agency Name is required.
            </p>
            <p *ngIf="registerForm?.get('agencyName')?.errors?.['pattern']">
              Valid Characters (A-Z, spaces and '-').
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="required">Requester's First Name</label>
          <input
            formControlName="requesterFirstName"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('requesterFirstName')?.dirty &&
              registerForm?.get('requesterFirstName')?.invalid
            "
          >
            <p
              *ngIf="registerForm?.get('requesterFirstName')?.errors?.['required']"
            >
              Agency Name is required.
            </p>
            <p
              *ngIf="registerForm?.get('requesterFirstName')?.errors?.['pattern']"
            >
              Valid Characters (A-Z, spaces and '-').
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="required">Requester's Last Name</label>
          <input
            formControlName="requesterLastName"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('requesterLastName')?.dirty &&
              registerForm?.get('requesterLastName')?.invalid
            "
          >
            <p
              *ngIf="registerForm?.get('requesterLastName')?.errors?.['required']"
            >
              Agency Name is required.
            </p>
            <p
              *ngIf="registerForm?.get('requesterLastName')?.errors?.['pattern']"
            >
              Valid Characters (A-Z, spaces and '-').
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="required">Requester's Title</label>
          <input
            formControlName="requesterTitle"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('requesterTitle')?.dirty &&
              registerForm?.get('requesterTitle')?.invalid
            "
          >
            <p
              *ngIf="registerForm?.get('requesterTitle')?.errors?.['required']"
            >
              Agency Name is required.
            </p>
            <p *ngIf="registerForm?.get('requesterTitle')?.errors?.['pattern']">
              Valid Characters (A-Z, spaces and '-').
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5">
      <div class="row m-0 p-0">
        <h4 class="section-header required">
          Select Ameren State <span class="required-field">*</span>
        </h4>
        <div
          class="form-group col-md-6 new_agency_state col-sm-6 d-inline"
          (click)="activateMissouriButton(true)"
        >
          <input
            class="form-control"
            [ngClass]="isMissouri ? 'button-font-active' : 'button-font'"
            type="button"
            value="Missouri (MO)"
          />
          <input
            class="form-check-input radio-button"
            type="radio"
            [checked]="isMissouri"
            id="flexRadioDefault1"
          />
          <div class="text-field-error ameren-font-sm"></div>
          <img
            src="../../../assets/icon/missouri.svg"
            class="map-icon"
            width="20"
            height="20"
            alt="Select Missouri State"
          />
        </div>
        <div
          class="form-group col-md-6 new_agency_state col-sm-6 d-inline"
          (click)="activateIllinoisButton(false)"
        >
          <input
            class="form-control"
            [ngClass]="isIllinois ? 'button-font-active' : 'button-font'"
            type="button"
            value="Illinois (IL)"
          />
          <input
            class="form-check-input radio-button"
            type="radio"
            [checked]="isIllinois"
            id="flexRadioDefault1"
          />
          <div class="text-field-error ameren-font-sm"></div>
          <img
            src="../../../assets/icon/illinois.svg"
            class="map-icon"
            width="20"
            height="20"
            alt="Select Illinois State"
          />
        </div>
      </div>
      <div class="text-field-error ameren-font-sm" *ngIf="errorMessage != ''">
        {{ errorMessage }}
      </div>
    </section>

    <section class="mb-5">
      <div class="row m-0">
        <h4 class="section-header">Contact Information</h4>
        <div class="form-group">
          <label class="required">Email Address</label>
          <input
            formControlName="emailAddress"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('emailAddress')?.dirty &&
              registerForm?.get('emailAddress')?.invalid
            "
          >
            <p *ngIf="registerForm?.get('emailAddress')?.errors?.['required']">
              Email Address is required.
            </p>
            <p *ngIf="registerForm?.get('emailAddress')?.errors?.['email']">
              Invalid Email Address.
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="required">Phone Number</label>
          <input
            amerenInputMask="999.999.9999"
            formControlName="phoneNumber"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('phoneNumber')?.dirty &&
              registerForm?.get('phoneNumber')?.invalid
            "
          >
            <p *ngIf="registerForm?.get('phoneNumber')?.errors?.['required']">
              Phone Number is required.
            </p>
            <p *ngIf="registerForm?.get('phoneNumber')?.errors?.['pattern']">
              Invalid Phone Number.
            </p>
          </div>
        </div>
        <div class="form-group">
          <label>Fax</label>
          <input
            amerenInputMask="999.999.9999"
            formControlName="fax"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('fax')?.dirty &&
              registerForm?.get('fax')?.invalid
            "
          >
            Invalid Fax
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5">
      <div class="row m-0" formGroupName="agencyAddress">
        <h4 class="section-header">Agency Address</h4>
        <div class="form-group">
          <label class="required">Address Line 1</label>
          <input
            formControlName="addressLine1"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('agencyAddress')?.get('addressLine1')?.dirty &&
              registerForm?.get('agencyAddress')?.get('addressLine1')?.invalid
            "
          >
            Address Line 1 is required.
          </div>
        </div>
        <div class="form-group">
          <label>Address Line 2</label>
          <input
            formControlName="addressLine2"
            class="form-control"
            type="text"
          />
        </div>
        <div class="form-group">
          <label class="required">City</label>
          <input formControlName="city" class="form-control" type="text" />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('agencyAddress')?.get('city')?.dirty &&
              registerForm?.get('agencyAddress')?.get('city')?.invalid
            "
          >
            <p
              *ngIf="registerForm?.get('agencyAddress')?.get('city')?.errors?.['required']"
            >
              City is required.
            </p>
            <p
              *ngIf="registerForm?.get('agencyAddress')?.get('city')?.errors?.['pattern']"
            >
              Valid Characters (A-Z, spaces and '-').
            </p>
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="form-group col-md-6 new_agency_state d-inline">
            <label class="required form-label">State</label>
            <select class="form-select" formControlName="state">
              <option
                *ngFor="let stlist of states; let i = index"
                [value]="stlist.abbreviation"
              >
                {{ stlist.name }}
              </option>
            </select>
            <div
              class="text-field-error ameren-font-sm"
              *ngIf="
                registerForm?.get('agencyAddress')?.get('state')?.dirty &&
                registerForm?.get('agencyAddress')?.get('state')?.invalid
              "
            >
              State is required.
            </div>
          </div>
          <div class="form-group col-md-6 new_agency_state col-sm-6 d-inline">
            <label class="required">Zip</label>
            <input formControlName="zip" class="form-control" type="text" />
            <div
              class="text-field-error ameren-font-sm"
              *ngIf="
                registerForm?.get('agencyAddress')?.get('zip')?.dirty &&
                registerForm?.get('agencyAddress')?.get('zip')?.invalid
              "
            >
              <p
                *ngIf="registerForm?.get('agencyAddress')?.get('zip')?.errors?.['required']"
              >
                Zip is required.
              </p>
              <p
                *ngIf="registerForm?.get('agencyAddress')?.get('zip')?.errors?.['pattern']"
              >
                Invalid Zip.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5">
      <div class="row m-0" formGroupName="mailingAddress">
        <h4 class="section-header">Mailing Address</h4>
        <label class="register_agency_form_check ameren-font-sm">
          <input
            (change)="onSameAsClick()"
            class="register_agency_form_check_input"
            type="checkbox"
          />
          <span class="register_agency_form_check_label">
            Same as Agency Address
          </span>
        </label>
        <div class="form-group">
          <label class="required">Address Line 1</label>
          <input
            formControlName="addressLine1"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('mailingAddress')?.get('addressLine1')?.dirty &&
              registerForm?.get('mailingAddress')?.get('addressLine1')?.invalid
            "
          >
            Address Line 1 is required.
          </div>
        </div>
        <div class="form-group">
          <label>Address Line 2</label>
          <input
            formControlName="addressLine2"
            class="form-control"
            type="text"
          />
        </div>
        <div class="form-group">
          <label class="required">City</label>
          <input formControlName="city" class="form-control" type="text" />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              registerForm?.get('mailingAddress')?.get('city')?.dirty &&
              registerForm?.get('mailingAddress')?.get('city')?.invalid
            "
          >
            Address Line 1 is required.
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="form-group col-md-6 d-inline new_agency_state">
            <label class="required form-label">State</label>
            <select class="form-select" formControlName="state">
              <option
                *ngFor="let stlist of states; let i = index"
                [value]="stlist.abbreviation"
              >
                {{ stlist.name }}
              </option>
            </select>
            <div
              class="text-field-error ameren-font-sm"
              *ngIf="
                registerForm?.get('mailingAddress')?.get('state')?.dirty &&
                registerForm?.get('mailingAddress')?.get('state')?.invalid
              "
            >
              State is required.
            </div>
          </div>
          <div class="form-group col-md-6 d-inline pr-0 new_agency_state">
            <label class="required">Zip</label>
            <input formControlName="zip" class="form-control" type="text" />
            <div
              class="text-field-error ameren-font-sm"
              *ngIf="
                registerForm?.get('mailingAddress')?.get('zip')?.dirty &&
                registerForm?.get('mailingAddress')?.get('zip')?.invalid
              "
            >
              <p
                *ngIf="registerForm?.get('mailingAddress')?.get('zip')?.errors?.['required']"
              >
                Zip is required.
              </p>
              <p
                *ngIf="registerForm?.get('mailingAddress')?.get('zip')?.errors?.['pattern']"
              >
                Invalid Zip.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5">
      <div class="row m-0" formArrayName="representatives">
        <h4 class="section-header">Representative</h4>
        <p class="register_agency_info_text p-0">
          <em>Can add up to 8 representatives</em>
        </p>
        <div
          class="p-0"
          *ngFor="let rep of representatives.controls; let idx = index"
        >
          <div class="row m-0 new_agency_rep" [formGroupName]="idx">
            <div class="form-group col-md-6 new_agency_state">
              <label>First Name</label>
              <input
                formControlName="firstName"
                type="text"
                class="form-control"
              />
              <div
                class="text-field-error ameren-font-sm"
                *ngIf="
                  representatives.controls[idx]?.get('firstName')?.dirty &&
                  representatives.controls[idx]?.get('firstName')?.invalid
                "
              >
                Invalid First Name.
              </div>
            </div>
            <div class="form-group col-md-6 new_agency_state">
              <label>Last Name</label>
              <input
                formControlName="lastName"
                type="text"
                class="form-control"
              />
              <div
                class="text-field-error ameren-font-sm"
                *ngIf="
                  representatives.controls[idx]?.get('lastName')?.dirty &&
                  representatives.controls[idx]?.get('lastName')?.invalid
                "
              >
                Invalid Last Name.
              </div>
              <div class="row m-0 new_agency_rep">
                <div class="form-group col-md-6 new_agency_state">
                  <a
                    (click)="addrep()"
                    *ngIf="representatives.controls.length < 8"
                    class="text-right"
                    >+ Additional</a
                  >
                </div>
                <div class="form-group col-md-6 new_agency_state">
                  <a
                    (click)="deleterep(idx)"
                    *ngIf="representatives.controls.length > 1"
                    class="text-left"
                    >- Additional</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row justify-content-md-center m-0">
        <div class="ameren-font-sm new_agency_form_terms">
          <div class="form-check mb-1 term">
            <input
              type="checkbox"
              class="form-check-input fs-5"
              formControlName="referrals"
            />
            <label for="termCheckChecked" class="form-check-label mt-1">
              Receive customer referrals from Ameren (Optional)
            </label>
          </div>
          <div
            class="row m-0"
            *ngIf="registerForm?.get('referrals')?.value"
            formArrayName="zipcodes"
          >
            <p class="referral-description">
              Please list the zip codes of the cities your agency serves
            </p>
            <div
              class="p-0"
              *ngFor="let zip of zipcodes.controls; index as idx"
            >
              <div class="row m-0 new_agency_rep" [formGroupName]="idx">
                <div class="form-group">
                  <label class="required">Zip Codes</label>
                  <input
                    formControlName="zip"
                    type="text"
                    class="form-control"
                  />
                  <div
                    class="text-field-error ameren-font-sm"
                    *ngIf="
                      (zipcodes.controls[idx]?.get('zip')?.dirty ||
                        zipcodes.controls[idx]?.get('zip')?.touched) &&
                      zipcodes.controls[idx]?.get('zip')?.invalid
                    "
                  >
                    <div
                      *ngIf="zipcodes.controls[idx]?.get('zip')?.errors?.['required']"
                    >
                      This field is required.
                    </div>
                    <div
                      *ngIf="!zipcodes.controls[idx]?.get('zip')?.errors?.['required']"
                    >
                      Invalid Zip Code.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a (click)="addzip()" class="text-right">+ Additional</a>
          </div>

          <div class="form-check term mb-1">
            <input
              type="checkbox"
              class="form-check-input fs-5"
              formControlName="acceptAuthorizationAndConfStatements"
            />
            <label for="termCheckChecked" class="form-check-label mt-1">
              I agree and read to the
              <a href="javascript:void(0)" (click)="showAuthorizationModal()"
                >authorization and confidentiality statements.</a
              >
            </label>
          </div>
          <div
            class="text-field-error ameren-font-sm pl-3"
            *ngIf="
              registerForm?.get('acceptAuthorizationAndConfStatements')
                ?.dirty &&
              registerForm?.get('acceptAuthorizationAndConfStatements')?.invalid
            "
          >
            Please agree to the authorization and confidentiality statements.
          </div>
          <div class="form-check term mb-1">
            <input
              type="checkbox"
              class="form-check-input fs-5"
              formControlName="termcondition"
            />
            <label for="termCheckChecked" class="form-check-label mt-1">
              I agree and read to the
              <a href="javascript:void(0)" (click)="showTermsModal()"
                >portal access terms and conditions.</a
              >
            </label>
          </div>
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="stateErrorMessage != ''"
          >
            {{ stateErrorMessage }}
          </div>
          <div
            class="text-field-error ameren-font-sm pl-3"
            *ngIf="
              registerForm?.get('termcondition')?.dirty &&
              registerForm?.get('termcondition')?.invalid
            "
          >
            Please agree to the portal access terms and conditions.
          </div>

          <div class="recaptcha__foot-note">
            This site is protected by reCAPTCHA and the Google
            <a
              href="https://policies.google.com/privacy"
              rel="noopener"
              target="_blank"
              alt="Google Policy"
              >Privacy Policy</a
            >
            and
            <a
              alt="Google terms"
              rel="noopener"
              target="_blank"
              href="https://policies.google.com/terms"
              >Terms of Service</a
            >
            apply
          </div>
        </div>

        <ameren-loading
          *ngIf="isLoading"
          class="d-flex justify-content-center"
        ></ameren-loading>
        <div *ngIf="isAPIFailure" class="mt-3 alert alert-danger" role="alert">
          {{ integrationErrorMsg }}
        </div>
        <div class="text-center">
          <button
            [disabled]="!registerForm.valid || disabled"
            (click)="verifyRecaptchaForAgencyRegistration()"
            class="btn btn-success add_agency_submit"
          >
            Submit
          </button>
        </div>
      </div>
    </section>
  </form>
</div>
<div
  class="modal fade"
  id="authorizationModal"
  tabindex="-1"
  aria-labelledby="authorizationModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog authorization_model" id="authorizationModalDialog">
    <div class="modal-content" id="authorizationModalContent">
      <div class="pop-header row" id="authorizationModalHeader">
        <p
          class="modal-title text-center textColor col-md-11"
          id="authorizationModalLabel"
        >
          <strong><u>Authorization and Confidentiality Statement</u></strong>
        </p>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" id="authorizationModalText">
        <p>
          I certify that these individuals are authorized to receive and provide
          information to Ameren for the purpose or providing One UI Portal to
          clients served by the agency which name and address appear on this
          form. I also certify that should any of this information change, I
          will notify Ameren immediately. I understand that a confidential
          identification number will be issued to this agency by Ameren upon
          Ameren's receipt of this completed and signed form. I agree to make
          this identification number available to only the individuals
          representing this agency whose names appear above.
        </p>
        <p>
          I further understand that any client account information I receive
          from Ameren is confidential as to the client, the agency, and Ameren;
          and I agree to take all resonable safeguards, on behalf of the agency,
          to prevent further dissemination of this information whithout consent
          of the client. I represent to Ameren that this agency will not attempt
          to obtain any client information from Ameren until the agency has
          obtained the express consent of the client.
        </p>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="termsMOModal"
  tabindex="-1"
  aria-labelledby="termsModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog terms_model" id="termsModalDialog">
    <div class="modal-content" id="termsModalContent">
      <div class="pop-header row" id="termsModalHeader">
        <p
          class="modal-title text-center textColor col-md-11"
          id="termsModalLabel"
        >
          <strong><u>PORTAL ACCESS TERMS & CONDITIONS</u></strong>
        </p>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" id="termsModalText">
        <strong>ACCOUNT ACCESS</strong>
        <p>
          Agency and Representatives of Agency understand and agree that they
          are solely responsible for maintaining the integrity of the Agency's
          portal PIN and that each representative for the Agency must have their
          own unique PIN. Sharing of a representative's portal PIN with anyone
          is prohibited. The Agency PIN will only be used to access a client's
          Ameren Missouri customer account information if Agency has obtained
          written authorization from each of its One UI Portal ("EA") clients to
          access the client's Ameren Missouri customer account information
          before accessing the client's Ameren Missouri customer account
          information via the portal. Any client account information received
          from <strong>Ameren Missouri</strong> and through the portal is
          confidential as to the client, the Agency, and
          <strong>Ameren Missouri</strong>, and Agency will take all reasonable
          safeguards to prevent further disclosure of this information without
          consent of the client. Agency and Representatives understand and agree
          to notify Ameren Missouri immediately if they believe the Agency's PIN
          has been compromised.
        </p>
        <strong>TRAINING ON USE OF PORTAL</strong>
        <p>
          Ameren Missouri will offer Agency and Representatives training on how
          to access Ameren Missouri customer account information and make
          pledges via the portal. Agency and Representatives agree to
          participate in such training at least annually.
        </p>
        <strong>PLEDGES AND PAYMENTS</strong>
        <p>
          A "pledge" is a promise by the EA Agency to pay the pledged amount
          toward a client's Ameren Missouri customer account, and may cause a
          client's Ameren Missouri customer account to be removed from the
          disconnection queue. Pledges are entered by Agency and Representatives
          through Ameren Missouri's EA Agency portal. Agency is responsible for
          securing any third-party funding for pledges it enters into the
          portal. Partial payments of pledges will not be accepted.
        </p>
        <p>
          Once the pledge is made, actual payment should be received by Ameren
          Missouri within 30 calendar days of the pledge being entered in the
          portal. Payment of a pledge via Electronic Funds Transfer ("EFT") is
          encouraged to meet the pledge/payment timeframes, but EFT is not
          required. If actual payment of the full pledged amount is not received
          by Ameren Missouri within 45 days of the pledge being entered in the
          portal, a notification of late payment will appear in the portal for
          the Agency and Representatives. If actual payment of the pledged
          amount is not received by Ameren Missouri within 60 days of the pledge
          being entered in the portal, Ameren Missouri's EA outreach team will
          reach out to the Agency and Representatives to discuss the outstanding
          payment and work out a payment plan. If actual payment of the pledged
          amount is not received by Ameren Missouri within 90 days of the pledge
          being entered in the portal, and Ameren Missouri has not received a
          payment plan from the Agency, the Agency's portal PIN may be
          deactivated. If an agency has more than 25% of the total dollars they
          have pledged in a calendar year open and unpaid beyond 60 days from
          the date pledged, the Agency's portal PIN may be deactivated. Please
          note, exceptions can be made due to government funding sources that
          payment terms to the Agency extend beyond 90 days with approval by
          Ameren Missouri leadership.
        </p>
        <p>
          If a payment is submitted for multiple pledges and/or customers, the
          Agency will submit a spreadsheet identifying each pledge/customer and
          corresponding amount of payment to be applied to the customer's
          account.
        </p>
        <strong>NO WARRANTIES AND LIMITATION OF LIABILITY</strong>
        <p>
          Ameren Missouri strives to ensure the accuracy of the information
          presented in the portal. However, due to various circumstances
          including, but not limited to, delays caused by postal handling,
          upgrades and/or changes to Ameren's bill and payment processing
          system, there may be variances between the information available on
          the portal and customer bill, payment, or other customer account
          information. For this reason, the accuracy, truthfulness or
          reliability of any information provided on or by means of the portal
          is not guaranteed.
        </p>
        <p>
          AMEREN MISSOURI, ON BEHALF OF ITSELF AND ALL OF ITS AFFILIATES,
          SUBSIDIARIES, BUSINESS UNITS, CONTENT PROVIDERS, AGENTS AND THE LIKE,
          HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND/OR
          REPRESENTATIONS, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN,
          INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF
          MERCHANTABILITY, REASONABLE CARE, COMPATIBILITY, SECURITY,
          RELIABILITY, ACCURACY, AND/OR FITNESS FOR A PARTICULAR PURPOSE
          (WHETHER OR NOT AMEREN KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED, OR
          IS OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE), IN EACH INSTANCE IN
          RESPECT OF THE PORTAL. FURTHERMORE, AMEREN, ON BEHALF OF ITSELF AND
          ALL OF ITS AFFILIATES, SUBSIDIARIES, BUSINESS UNITS, CONTENT
          PROVIDERS, AGENTS AND THE LIKE, HEREBY EXPRESSLY DISCLAIMS ANY AND ALL
          WARRANTIES OF TITLE AND/OR NON-INFRINGEMENT IN RESPECT OF THE PORTAL.
        </p>
        <strong>MODIFICATION</strong>
        <p>
          Ameren Missouri may at any time modify these Terms & Conditions, and
          Agency's continued use of the portal will indicate your agreement to
          be governed by the terms and conditions in force at the time of your
          use.
        </p>
        <strong>SEVERABILITY</strong>
        <p>
          These Terms and Conditions shall be deemed severable. In the event
          that any provision is determined to be unenforceable or invalid, such
          provision shall nonetheless be enforced to the fullest extent
          permitted by applicable law, and such determination shall not affect
          the validity and enforceability of any other remaining provisions.
        </p>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="termsILModal"
  tabindex="-1"
  aria-labelledby="termsModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog terms_model" id="termsModalDialog">
    <div class="modal-content" id="termsModalContent">
      <div class="pop-header row" id="termsModalHeader">
        <p
          class="modal-title text-center textColor col-md-11"
          id="termsModalLabel"
        >
          <strong><u>PORTAL ACCESS TERMS & CONDITIONS</u></strong>
        </p>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" id="termsModalText">
        <strong>ACCOUNT ACCESS</strong>
        <p>
          Agency and Representatives of Agency understand and agree that they
          are solely responsible for maintaining the integrity of the Agency's
          portal PIN and that each representative for the Agency must have their
          own unique PIN. Sharing of a representative's portal PIN with anyone
          is prohibited. The Agency PIN will only be used to access a client's
          Ameren Illinois customer account information if Agency has obtained
          written authorization from each of its One UI Portal ("EA") clients to
          access the client's Ameren Illinois customer account information
          before accessing the client's Ameren Illinois customer account
          information via the portal. Any client account information received
          from <strong>Ameren Illinois</strong> and through the portal is
          confidential as to the client, the Agency, and
          <strong>Ameren Illinois</strong>, and Agency will take all reasonable
          safeguards to prevent further disclosure of this information without
          consent of the client. Agency and Representatives understand and agree
          to notify Ameren Illinois immediately if they believe the Agency's PIN
          has been compromised.
        </p>
        <strong>TRAINING ON USE OF PORTAL</strong>
        <p>
          Ameren Illinois will offer Agency and Representatives training on how
          to access Ameren Illinois customer account information and make
          pledges via the portal. Agency and Representatives agree to
          participate in such training at least annually.
        </p>
        <strong>PLEDGES AND PAYMENTS</strong>
        <p>
          A "pledge" is a promise by the EA Agency to pay the pledged amount
          toward a client's Ameren Illinois customer account and may cause a
          client's Ameren Illinois customer account to be removed from the
          disconnection queue. Pledges are entered by Agency and Representatives
          through Ameren Illinois's EA Agency portal. Agency is responsible for
          securing any third-party funding for pledges it enters in the portal.
          Partial payments of pledges will not be accepted.
        </p>
        <p>
          Once the pledge is made, actual payment should be received by Ameren
          Illinois within 30 calendar days of the pledge being entered in the
          portal. Payment of a pledge via Electronic Funds Transfer ("EFT") is
          encouraged to meet the pledge/payment timeframes, but EFT is not
          required. If actual payment of the full pledged amount is not received
          by Ameren Illinois within 45 days of the pledge being entered in the
          portal, a notification of late payment will appear in the portal for
          the Agency and Representatives. If actual payment of the pledged
          amount is not received by Ameren Illinois within 60 days of the pledge
          being entered in the portal, Ameren Illinois's EA outreach team will
          reach out to the Agency and Representatives to discuss the outstanding
          payment and work out a payment plan. If actual payment of the pledged
          amount is not received by Ameren Illinois within 90 days of the pledge
          being entered in the portal, and Ameren Illinois has not received a
          payment plan from the Agency, the Agency's portal PIN may be
          deactivated. If an agency has more than 25% of the total dollars, they
          have pledged in a calendar year open and unpaid beyond 60 days from
          the date pledged, the Agency's portal PIN may be deactivated. Please
          note, exceptions can be made due to government funding sources that
          payment terms to the Agency extend beyond 90 days with approval by
          Ameren Illinois leadership.
        </p>
        <p>
          If a payment is submitted for multiple pledges and/or customers, the
          Agency will submit a spreadsheet identifying each pledge/customer and
          corresponding amount of payment to be applied to the customer's
          account.
        </p>
        <strong>NO WARRANTIES AND LIMITATION OF LIABILITY</strong>
        <p>
          Ameren Illinois strives to ensure the accuracy of the information
          presented in the portal. However, due to various circumstances
          including, but not limited to, delays caused by postal handling,
          upgrades and/or changes to Ameren's bill and payment processing
          system, there may be variances between the information available on
          the portal and customer bill, payment, or other customer account
          information. For this reason, the accuracy, truthfulness or
          reliability of any information provided on or by means of the portal
          is not guaranteed.
        </p>
        <p>
          AMEREN ILLINOIS, ON BEHALF OF ITSELF AND ALL OF ITS AFFILIATES,
          SUBSIDIARIES, BUSINESS UNITS, CONTENT PROVIDERS, AGENTS AND THE LIKE,
          HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND/OR
          REPRESENTATIONS, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN,
          INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF
          MERCHANTABILITY, REASONABLE CARE, COMPATIBILITY, SECURITY,
          RELIABILITY, ACCURACY, AND/OR FITNESS FOR A PARTICULAR PURPOSE
          (WHETHER OR NOT AMEREN KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED, OR
          IS OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE), IN EACH INSTANCE IN
          RESPECT OF THE PORTAL. FURTHERMORE, AMEREN, ON BEHALF OF ITSELF AND
          ALL OF ITS AFFILIATES, SUBSIDIARIES, BUSINESS UNITS, CONTENT
          PROVIDERS, AGENTS AND THE LIKE, HEREBY EXPRESSLY DISCLAIMS ANY AND ALL
          WARRANTIES OF TITLE AND/OR NON-INFRINGEMENT IN RESPECT OF THE PORTAL.
        </p>
        <strong>MODIFICATION</strong>
        <p>
          Ameren Illinois may at any time modify these Terms & Conditions, and
          Agency's continued use of the portal will indicate your agreement to
          be governed by the terms and conditions in force at the time of your
          use.
        </p>
        <strong>SEVERABILITY</strong>
        <p>
          These Terms and Conditions shall be deemed severable. If any provision
          is determined to be unenforceable or invalid, such provision shall
          nonetheless be enforced to the fullest extent permitted by applicable
          law, and such determination shall not affect the validity and
          enforceability of any other remaining provisions.
        </p>
      </div>
    </div>
  </div>
</div>
