<div class="login" *ngIf="!isAuthenticated">
  <div class="container p-4">
    <div class="form-group">
      <div class="text-center login__heading">ESS Portal</div>
    </div>
    <div class="form-group">
      <button class="form-control form-btn login_view" (click)="login()">
        <span class="login_text">Log In</span>
      </button>
    </div>

<div class="login" *ngIf="isAuthenticated">
  <div class="container p-4">
    <div class="form-group">
      <div class="text-center login__heading">Welcome to One UI Portal</div>
    </div>
    <div class="form-group text-center">
      View your accounts, pledge amount and view billing history.
    </div>
    <div class="form-group">
      <button
        class="form-control form-btn btn-success"
        *ngIf="isAdmin"
        (click)="goToPath()"
      >
        Go to my Home Page
      </button>
    </div>
    <div class="form-group">
      <div class="login__border text-center">or</div>
    </div>
    <div class="form-group">
      <button
        class="form-control form-btn rounded-pill border border-info"
        (click)="logout()"
      >
        Log Out
      </button>
    </div>
  </div>
</div>
