import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-point-reference-date',
  templateUrl: './service-point-reference-date.component.html',
  styleUrl: './service-point-reference-date.component.scss'
})
export class ServicePointReferenceDateComponent {
  public s7: Date;
  public s14: Date;
  public s55: Date;
  public s105: Date;
  public referenceDate: Date;

  constructor(private fb: FormBuilder, private router: Router) {
  }

  referenceDateForm = this.fb.group({
    referenceDate: new Date()
  })

  ngOnInit() {
    let today: Date = new Date(Date.now());
    this.s7 = this.addDays(today, -7);
    this.s14 = this.addDays(today, -14);
    this.s55 = this.addDays(today, -55);
    this.s105 = this.addDays(today, -105);
    this.referenceDate = new Date("2024-04-10 12:00");
    this.referenceDateForm.controls.referenceDate.patchValue(this.referenceDate);
  }

  addDays(startDate: Date, days: number): Date {
    var date = new Date(startDate.valueOf())
    date.setDate(date.getDate() + days);
    return date;
  }

  getServicePoints(date: string) {

    var setDate: string;

    switch(date) {
      case 's7':
        setDate = this.s7.toISOString().substring(0, 10);
        break;
      case 's14':
        setDate = this.s14.toISOString().substring(0, 10);
        break;
      case 's55':
        setDate = this.s55.toISOString().substring(0, 10);
        break;
      case 's105':
        setDate = this.s105.toISOString().substring(0, 10);
        break;
      case 'custom':
        let val = this.referenceDateForm.get('referenceDate')?.value!;
        setDate = val.toISOString().substring(0, 10);
        break;
      default: 
        setDate = this.referenceDate.toISOString().substring(0, 10);
    }

    this.router.navigate(['/service-points'], { queryParams : { reference_date: setDate } });
  }
}
