<div class="carousel_row" [ngClass]="slideClass">
    <ng-container *ngFor="let slide of slides; let i = index">
        <div class="carousel_column carousel_slide" *ngIf="i >= currentDisplay.start && i <= currentDisplay.end">
            <img *ngIf="slide.img_src" [src]="slide.img_src" [alt]="slide.img_alt" class="carousel_slide" />

            <div class="carousel_inner">
                <h4 class="carousel_heading">{{slide.headline}}</h4>
                <p class="carousel_inner_body">
                    {{slide.description}}
                </p>
                <a class="carousel_inner_link" [href]="slide.linkUrl">
                    <span class="carousel_inner_linkText">{{slide.linkText}}</span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </a>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="fillColumn">
        <ng-container *ngFor="let p of fillColumnArray">
            <div class="carousel_column carousel_slide carousel_hide">
                Empty carousel 
            </div>

        </ng-container>
    </ng-container>

    <button class="carousel_left carousel_btn-side" (click)="onPreviousClick()">

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg>
    </button>
    <button class="carousel_right carousel_btn-side" (click)="onNextClick()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
        </svg>
    </button>

</div>
<div class="carousel_row">
    <div class="carousel_column ameren_carousel_indicators">
        <button class="carousel_btn-circle" *ngFor="let n of numbersArray" [class.active]="n==selectedPosition"
            (click)="onCircleClick(n)"></button>
    </div>

</div>