<div class="service-point-summary">

    <h1 style="color:black; text-align:left">Service Point Summary</h1>

    <div class="scrolling-container">
        <mat-tab-group animationDuration="0ms">
            <mat-tab label="Details" ><app-service-point-details [servicePoint]="servicePoint" [referenceDate]="referenceDate"></app-service-point-details></mat-tab>
            <mat-tab label="Interval Usage"> <app-interval-usage [servicePointId]="servicePointId"></app-interval-usage> </mat-tab>
            <mat-tab label="Scalar Usage"> <app-scalar-usage [servicePointId]="servicePointId"></app-scalar-usage> </mat-tab>
        </mat-tab-group>
    </div>
</div>