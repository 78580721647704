<div class="reference-date-select">
    <h2>Reference Date Selection</h2>
    <div style="display: flex">
        <mat-card appearance="outlined" class="mat-elevation-z0 date-option">
            <mat-card-title>S7</mat-card-title>
            <mat-card-subtitle>Today -7</mat-card-subtitle>
            <p>{{this.s7 | date:'shortDate'}}</p>
            <mat-card-footer class="card-footer">
                <button mat-raised-button (click)="getServicePoints('s7')">Go!</button>
            </mat-card-footer>
        </mat-card>
        <mat-card appearance="outlined" class="mat-elevation-z0 date-option">
            <mat-card-title>S14</mat-card-title>
            <mat-card-subtitle>Today -14</mat-card-subtitle>
            <p>{{this.s14 | date:'shortDate'}}</p>
            <mat-card-footer class="card-footer">
                <button mat-raised-button (click)="getServicePoints('s14')">Go!</button>
            </mat-card-footer>
        </mat-card>
        <mat-card appearance="outlined" class="mat-elevation-z0 date-option">
            <mat-card-title>S55</mat-card-title>
            <mat-card-subtitle>Today -55</mat-card-subtitle>
            <p>{{this.s55 | date:'shortDate'}}</p>
            <mat-card-footer class="card-footer">
                <button mat-raised-button (click)="getServicePoints('s55')">Go!</button>
            </mat-card-footer>
        </mat-card>
        <mat-card appearance="outlined" class="mat-elevation-z0 date-option">
            <mat-card-title>S105</mat-card-title>
            <mat-card-subtitle>Today -105</mat-card-subtitle>
            <p>{{this.s105 | date:'shortDate'}}</p>
            <mat-card-footer class="card-footer">
                <button mat-raised-button (click)="getServicePoints('s105')">Go!</button>
            </mat-card-footer>
        </mat-card>
        <mat-card appearance="outlined" class="mat-elevation-z0 date-option">
            <mat-card-title>Custom</mat-card-title>
            <mat-card-subtitle>Select A Date</mat-card-subtitle>
            <form 
                [formGroup]="referenceDateForm"
                (ngSubmit)="$event.preventDefault();"
            >

                <mat-form-field>
                    <mat-label>Custom Date</mat-label>
                    <input 
                        formControlName="referenceDate"
                        matInput [matDatepicker]="referenceDate"
                    >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="referenceDate"></mat-datepicker-toggle>
                    <mat-datepicker #referenceDate></mat-datepicker>
                </mat-form-field>
            </form>
            <mat-card-footer class="card-footer">
                <button mat-raised-button (click)="getServicePoints('custom')">Go!</button>
            </mat-card-footer>
        </mat-card>
    </div>
</div>