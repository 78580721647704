<div class="profile-segments">

    <h1 style="text-align:left">Profile Segments</h1>

    <ag-grid-angular
        style="width: 100%; height: 800px;"
        [columnDefs]="colDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [rowData]="rowData"
        [class]="themeClass"    
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [autoSizeStrategy]="autoSizeStrategy"
        (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
</div>