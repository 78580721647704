<footer class="footer">
    <div class="footer-top text-center">
        <ul class="footer-items">
            <li class="bol text-center footer-logo hidden-xs">
                <a id="a-footer-home" class="logo-container" href="/" title="homepage">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186 71"
                        aria-hidden="true">
                        <defs>
                            <style>
                                .cls-1,
                                .cls-2,
                                .cls-3 {
                                    fill: #fff;
                                }

                                .cls-1,
                                .cls-2,
                                .cls-3 {
                                    fill-rule: evenodd;
                                }
                            </style>
                        </defs>
                        <title>logo_ameren_default</title>
                        <g>
                            <path class="cls-1" d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"></path>
                            <path class="cls-2"
                                d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z">
                            </path>
                            <path class="cls-1"
                                d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z">
                            </path>
                            <path class="cls-1"
                                d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z"></path>
                            <path class="cls-1"
                                d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z">
                            </path>
                            <path class="cls-1"
                                d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z">
                            </path>
                            <path class="cls-1"
                                d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z">
                            </path>
                            <path class="cls-1"
                                d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z">
                            </path>
                            <path class="cls-1"
                                d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z">
                            </path>
                            <path class="cls-1"
                                d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z">
                            </path>
                            <path class="cls-1"
                                d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z">
                            </path>
                            <path class="cls-3"
                                d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z">
                            </path>
                            <path class="cls-3"
                                d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z">
                            </path>
                            <path class="cls-3"
                                d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z">
                            </path>
                            <path class="cls-3"
                                d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z">
                            </path>
                            <path class="cls-3"
                                d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z">
                            </path>
                            <path class="cls-3"
                                d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z">
                            </path>
                        </g>
                    </svg>

                </a>
            </li>
            <li *ngFor="let l of topLinks" class="bol footer-nav-item text-center text-uppercase"
                [ngClass]="{'hidden':!topLinks || topLinks.length==0}">
                <a href="{{l.href}}">{{l.text}}</a>
            </li>
        </ul>
    </div>
    <div class="footer-bottom text-center">
        <div class="bol text-center footer-logo hidden-sm hidden-md hidden-lg">
            <a id="a-footer-home-s" class="logo-container mobile-logo-container" href="/" title="homepage">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186 71"
                    aria-hidden="true">
                    <defs>
                        <style>
                            .cls-1,
                            .cls-2,
                            .cls-3 {
                                fill: #fff;
                            }

                            .cls-1,
                            .cls-2,
                            .cls-3 {
                                fill-rule: evenodd;
                            }
                        </style>
                    </defs>
                    <title>logo_ameren_default</title>
                    <g>
                        <path class="cls-1" d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"></path>
                        <path class="cls-2"
                            d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z">
                        </path>
                        <path class="cls-1"
                            d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z"></path>
                        <path class="cls-1" d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z">
                        </path>
                        <path class="cls-1"
                            d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z">
                        </path>
                        <path class="cls-1"
                            d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z">
                        </path>
                        <path class="cls-1"
                            d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z">
                        </path>
                        <path class="cls-1"
                            d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z">
                        </path>
                        <path class="cls-1"
                            d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z">
                        </path>
                        <path class="cls-1"
                            d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z">
                        </path>
                        <path class="cls-1"
                            d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z">
                        </path>
                        <path class="cls-3"
                            d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z">
                        </path>
                        <path class="cls-3"
                            d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z">
                        </path>
                        <path class="cls-3"
                            d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z">
                        </path>
                        <path class="cls-3"
                            d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z">
                        </path>
                        <path class="cls-3"
                            d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z">
                        </path>
                        <path class="cls-3"
                            d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z">
                        </path>
                    </g>
                </svg>

            </a>
        </div>
        <ul>
            <li class="bol text-center footer-bottom-nav-item d-sm-block">Copyright <span aria-hidden="true">©</span>
                {{currentYear}} Ameren Services</li>
            <li *ngFor="let b of bottomLinks" class="bol text-center footer-bottom-nav-item">
                <a href="{{b.href}}">{{b.text}}</a>
            </li>
        </ul>
    </div>
    <footer>