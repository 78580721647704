import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable()
export class EssApiService {
    constructor(private http: HttpClient) { }

    getMessage() {
        // TODO take out the hard-coding
        // and we need to figure out how to detect dev, stg, prd
        let header = new HttpHeaders({ 'Authorization': 'ZXNzNGV2ZXI=' })
        const requestOptions = { headers: header };
        return this.http.get('https://settlement-dev-api.np.ce.ameren.com/hellopage/', requestOptions);
    }
}
