import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SpaOverride } from 'src/app/shared/models/spaOverride';
import { SpaOverrideResponse } from 'src/app/shared/models/spaOverrideResponse';


export interface Filter {
  field: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class SpaOverrideService {

  constructor(private http: HttpClient) { }

  getOverrides(
    pageNumber = 0, 
    pageSize = 25,
    filters: Map<string, any>) : Observable<SpaOverrideResponse> {

    let start = pageNumber * pageSize;

    let params = new HttpParams();

    params = params.set('start', start);
    params = params.set('limit', pageSize);

    if (filters.size > 0) {
      for(let [key, value] of filters) {
        params = params.set(key, value);
      }
    }

    const requestOptions = { params: params };

    return this.http.get<SpaOverrideResponse>('spah', requestOptions )
    .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('There is an issue with the client or network:', error.error);
    } else {
      console.error('Server-side error: ', error.error)
    }

    return throwError(() => new Error('Cannot retrieve spa overrides from the server. Please try again.'));
  }
}
