export const environment = {
  production: true,
  environmentName: "PROD",
  cssEnv: "default",
  client_id: "aec2dad8-123-48c9-8645-2e34a287ddd1",
  authority:
    "https://login.eiam.ece.ameren.com/oauth2/realms/root/realms/partner/authorize",
  scope:
    "openid profile eapuseraccess ismemberof imp_agencyrolemap imp_email imp_username",
  amerenUrl: "https://www.ameren.com",
  authenticateUrl:
    "https://login.eiam.ece.ameren.com/json/realms/root/realms/ameren/authenticate",
  apiBaseUrl: "https://prd.ameren.io",
  openIGurl: "https://openig.eiam.ece.ameren.com",
  apig: "https://eap-prd-api.ce.ameren.com",
  changePasswordUrl:
    "https://login.eiam.ece.ameren.com/json/realms/partner/users",
  frXUI: "https://login.eiam.ameren.com/XUI",
  adfs: "https://login.eiam.ece.ameren.com:443/partneradfs",
  fed: "https://fed.ameren.com/adfs/ls/idpinitiatedsignon.aspx",
  recaptchaSiteKey: "6LckZkofAAAAANMCMFu1ZYvFqYS3wEt_R9bDlYpx",
  testEmail: "star@ameren.com", //Leave it blank for prod
};
