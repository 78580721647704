import { EditProfileComponent } from "./secure/manageprofile/edit-profile/edit-profile.component";
import { ManageprofileComponent } from "./secure/manageprofile/manageprofile.component";
import { RegisterNewAgencyComponent } from "./public/register-new-agency/register-new-agency.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./public/home/home.component";
import { RegistrationComponent } from "./public/registration/registration.component";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { RegistrationSuccessComponent } from "./public/registration/registration-success/registration-success.component";
import { SigninRedirectCallbackComponent } from "./public/signin-redirect-callback/signin-redirect-callback.component";
import { SignoutRedirectCallbackComponent } from "./public/signout-redirect-callback/signout-redirect-callback.component";
import { AgencyRegistrationSuccessComponent } from "./public/register-new-agency/agency-registration-success/agency-registration-success.component";
import { IneligibleUserComponent } from "./secure/ineligible-user/ineligible-user.component";
import { EmulationComponent } from "./public/emulation/emulation.component";
import { SpaOverridesComponent } from "./public/spa-overrides/spa-overrides.component";
import { EntityManagementComponent } from "./public/entities/entity-management.component";
import { ServicePointListComponent } from "./public/service-point-list/service-point-list.component";
import { ServicePointReferenceDateComponent } from "./public/service-point-reference-date/service-point-reference-date.component";
import { ServicePointDetailsComponent } from "./public/service-point-details/service-point-details.component";
import { ServicePointSummaryComponent } from "./public/service-point-summary/service-point-summary.component";
import { ProfileSegmentComponent } from "./public/profile-segment/profile-segment.component";
import { ScalarProfilingComponent } from "./public/scalar-profiling/scalar-profiling.component";
import { PlanningYearComponent } from "./public/planning-year/planning-year.component";

const routes: Routes = [
  {
    path: "home",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "illinois",
    component: HomeComponent,
  },
  {
    path: "missouri",
    component: HomeComponent,
  },
  {
    path: "registration",
    component: RegistrationComponent,
  },
  {
    path: "missouri/registration",
    component: RegistrationComponent,
  },
  {
    path: "illinois/registration",
    component: RegistrationComponent,
  },
  {
    path: "missouri/agency-registration",
    component: RegisterNewAgencyComponent,
  },
  {
    path: "illinois/agency-registration",
    component: RegisterNewAgencyComponent,
  },
  {
    path: "agency-registration",
    component: RegisterNewAgencyComponent,
  },
  {
    path: "registration-success",
    component: RegistrationSuccessComponent,
  },
  {
    path: "agency-registration-success",
    component: AgencyRegistrationSuccessComponent,
  },
  {
    path: "signincallback",
    component: SigninRedirectCallbackComponent,
  },
  {
    path: "signoutcallback",
    component: SignoutRedirectCallbackComponent,
  },
  {
    path: "manage-profile",
    component: ManageprofileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "edit-profile",
    component: EditProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "ineligible-user",
    component: IneligibleUserComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "emulation",
    component: EmulationComponent,
  },
  {
    path: "overrides",
    component: SpaOverridesComponent
  },
  {
    path: "service-points",
    component: ServicePointListComponent
  },
  {
    path: "entity-managment",
    component: EntityManagementComponent
  },
  {
    path: "service-point-reference-date-select",
    component: ServicePointReferenceDateComponent
  },
  {
    path: "service-point",
    component: ServicePointSummaryComponent
  },
  {
    path: "service-point-detail",
    component: ServicePointDetailsComponent
  },
  {
    path: "profile-segment",
    component: ProfileSegmentComponent
  },
  {
    path: "scalar-profiling",
    component: ScalarProfilingComponent
  },
  {
    path: "planning-year",
    component: PlanningYearComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
