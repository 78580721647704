import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScalarProfilingService } from './service/scalar-profiling.service';
import { ProfileClassCode } from 'src/app/shared/enums/profileClassCode';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-scalar-profiling',
  templateUrl: './scalar-profiling.component.html',
  styleUrl: './scalar-profiling.component.scss'
})
export class ScalarProfilingComponent {
  scalarProfilingForm = new FormGroup({
    operatingDay: new FormControl('', [Validators.required]),
    servicePointId: new FormControl<number | null>(null),
    profileClassCode: new FormControl('')
  });

  profileClassCodes: any;

  constructor(
    private service: ScalarProfilingService,
    private dateFormatter: DatePipe) {
  }
  ngOnInit() {
    let codes = Object.keys(ProfileClassCode).map(
      (code) => ({ key: code.replace("_", "-"), value: (ProfileClassCode as any)[code] })
    );

    this.profileClassCodes = codes;
  }

  runScalarProfilingJob() {
    let operatingDay = this.scalarProfilingForm.get("operatingDay")?.value;
    let servicePointId = this.scalarProfilingForm.get("servicePointId")?.value;
    let profileClassCode = this.scalarProfilingForm.get("profileClassCode")?.value;

    this.service.runScalarProfiling(this.dateFormatter.transform(operatingDay, 'YYYY-MM-dd')! ,servicePointId, profileClassCode).subscribe(

      (data : any) => {
          alert(`${data}`)
          this.scalarProfilingForm.disable();
      }
    );    
  }
}
