<div class="scalar-profiling">
    <h1>Scalar Profiling</h1>
    <p>
        Manually run profiling process
    </p>
    <form 
            class="scalar-profiling-form"
            [formGroup]="scalarProfilingForm"
            (ngSubmit)="$event.preventDefault(); runScalarProfilingJob()"
        >
        <table>
            <tr>
                <mat-form-field>
                    <mat-label>Operating Day</mat-label>
                    <input 
                        formControlName="operatingDay"
                        matInput [matDatepicker]="operatingDay"
                    >
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="operatingDay"></mat-datepicker-toggle>
                    <mat-datepicker #operatingDay></mat-datepicker>
                </mat-form-field>
                <mat-error *ngIf="scalarProfilingForm.controls['operatingDay'].invalid && (scalarProfilingForm.controls['operatingDay'].dirty || scalarProfilingForm.controls['operatingDay'].touched)" class="alert">
                    Operating day is required
                </mat-error>
            </tr>

            <tr>
                <mat-form-field>
                    <mat-label>Service Point ID</mat-label>
                    <input 
                        formControlName="servicePointId"
                        matInput
                    >
                </mat-form-field>
            </tr>

            <tr>
                <mat-form-field>
                    <mat-label>Profile Class Code</mat-label>
                    <mat-select 
                        formControlName="profileClassCode"
                        matInput
                    >
                        <mat-option *ngFor="let code of profileClassCodes" [value]="code.key">{{ code.value }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </tr>

            <tr>
                <button 
                    type="submit" 
                    mat-raised-button 
                    color="primary" 
                    [disabled]="!scalarProfilingForm.valid"
                >Run</button>
            </tr>
        </table>            
    </form>
</div>

