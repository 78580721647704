import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnumState } from '../enums/EnumState';
import { EnumToken } from '../enums/EnumToken';
import { Serializable } from '../models/Serializable';
import { EnumOneUIConfig } from '../enums/EnumOneUIConfig';

export class SplunkApiSuccessResponse extends Serializable {
  url?: string;
  apiResponse: any;
  status: any;
  statusText: string;
  apiRequest?: any;

  /*istanbul ignore next*/
  constructor(values: Object = {}) {
    super(values);
  }
}

export class SplunkApiSuccessRequest extends Serializable {
  url?: string;
  apiRequest?: any;
  status?: any;
  statusText?: string;

  /*istanbul ignore next*/
  constructor(values: Object = {}) {
    super(values);
  }
}

export class SplunkApiFailureRequest extends Serializable {
  url?: string;
  apiRequest?: any;
  status?: any;
  statusText?: string;
  error?: any;

  /*istanbul ignore next*/
  constructor(values: Object = {}) {
    super(values);
  }
}

export class SplunkApiFailureResponse extends Serializable {
  apiRequest?: any;
  url?: string;
  apiResponse: any;
  status: any;
  statusText: string;
  error?: any;

  /*istanbul ignore next*/
  constructor(values: Object = {}) {
    super(values);
  }
}

export enum EnumSplunkLevels {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export enum EnumSplunkSourceType {
  CLOUDHUBAPISUCCESSLOGS = 'api:success:logs',
  CLOUDHUBAPIFAILURELOGS = 'api:failure:logs',
  EAPUILOGS = 'ui:logs',
}

export class SplunkBodyInformation {
  sourcetype?: string;
  event?: SplunkEventInformation;
}

export class SplunkEventInformation {
  timestamp: any;
  state?: EnumState;
  userName?: string;
  portalName?: string;
  level: string;
  message?: any;
  elapsed?: number;
  method?: string;
  apiName?: string;
  profile?: any;
}

@Injectable({
  providedIn: 'root',
})
export class SplunkLoggerService {
  constructor(private http: HttpClient) {}

  logSplunk(payload: any, splunkInfo: any): Observable<any> {
    let splunkToken: EnumToken;
    let refererUrl;

    if (splunkInfo.env === 'DEV' && splunkInfo.portalName === 'EAP') {
      splunkToken = EnumToken.EAP_DEV_TOKEN;
      refererUrl = 'https://eap-dev.np.ce.ameren.com';
    } else if (splunkInfo.env === 'QA' && splunkInfo.portalName === 'EAP') {
      splunkToken = EnumToken.EAP_QA_TOKEN;
      refererUrl = 'https://eap-qa.np.ce.ameren.com';
    } else if (splunkInfo.env === 'PROD' && splunkInfo.portalName === 'EAP') {
      splunkToken = EnumToken.EAP_PROD_TOKEN;
      refererUrl = 'https://eap.ameren.com';
    } else if (splunkInfo.env === 'DEV' && splunkInfo.portalName === 'RES') {
      splunkToken = EnumToken.RES_DEV_TOKEN;
      refererUrl = 'https://res-dev.np.ce.ameren.com';
    } else if (splunkInfo.env === 'QA' && splunkInfo.portalName === 'RES') {
      splunkToken = EnumToken.RES_QA_TOKEN;
      refererUrl = 'https://res-qa.np.ce.ameren.com';
    } else if (splunkInfo.env === 'PROD' && splunkInfo.portalName === 'RES') {
      splunkToken = EnumToken.RES_PROD_TOKEN;
      refererUrl = 'https://res.ameren.com';
    } else if (splunkInfo.env === 'DEV' && splunkInfo.portalName === 'PORTAL') {
      splunkToken = EnumToken.PORTALS_DEV_TOKEN;
      refererUrl = 'https://portal-dev.np.ce.ameren.com';
    } else if (splunkInfo.env === 'QA' && splunkInfo.portalName === 'PORTAL') {
      splunkToken = EnumToken.PORTALS_QA_TOKEN;
      refererUrl = 'https://portal-qa.np.ce.ameren.com';
    } else if (
      splunkInfo.env === 'PROD' &&
      splunkInfo.portalName === 'PORTAL'
    ) {
      splunkToken = EnumToken.PORTALS_PROD_TOKEN;
      refererUrl = 'https://portal.ameren.com';
    } else if (splunkInfo.env === 'DEV' && splunkInfo.portalName === 'ANM') {
      splunkToken = EnumToken.PORTALS_DEV_TOKEN;
      refererUrl = 'https://anm-dev.np.ce.ameren.com';
    } else if (splunkInfo.env === 'QA' && splunkInfo.portalName === 'ANM') {
      splunkToken = EnumToken.PORTALS_QA_TOKEN;
      refererUrl = 'https://anm-qa.np.ce.ameren.com';
    } else if (splunkInfo.env === 'PROD' && splunkInfo.portalName === 'ANM') {
      splunkToken = EnumToken.PORTALS_PROD_TOKEN;
      refererUrl = 'https://anm.ameren.com';
    } else {
      splunkToken = splunkInfo.splunkToken;
      refererUrl = 'https://ameren.com';
    }

    const config = {
      token: splunkToken,
      url: EnumOneUIConfig.splunkUrl,
    };

    const headers = new HttpHeaders()
      .set('Authorization', `Splunk ${config.token}`)
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Referer', `${refererUrl}`);

    const body = payload;

    return this.http.post(`${config.url}`, body, { headers });
  }
}
