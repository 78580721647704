import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {
  ColDef, 
  RowModelType,
  GridReadyEvent,
  SizeColumnsToContentStrategy,
  GridApi,
} from 'ag-grid-community';

import { EntityTypeGroup } from 'src/app/shared/models/entityTypeGroup';
import { EntityService } from '../service/entity.service';

@Component({
  selector: 'app-entity-type-groups',
  templateUrl: './entity-type-groups.component.html',
  styleUrls: ['./entity-type-groups.component.scss']
})
export class EntityTypeGroupsComponent {

  private gridApi: GridApi;

  public rowData!: EntityTypeGroup[];

  filters: Map<string, any> = new Map();

  filterForm = new FormGroup({
    entityTypeFilter: new FormControl(''),
    entityGroupTypeFilter: new FormControl('')
  });

  colDefs: ColDef[] = [
    { field: "entity_type_group_name", headerName: "Entity Type Group", flex: 2 },
    { field: "entity_type_group_code", headerName: "Entity Type Group Code", flex: 1 },
    { field: "display_order", headerName: "Display Order", flex: 1 }
  ];

  public rowModelType: RowModelType = "clientSide";
  public rowSelection: "single" | "multiple" = "single";
  public paginationPageSize = 20;
  public themeClass: string = "ag-theme-material";

  constructor(private service: EntityService) { }  

  ngAfterViewInit(): void {
  }

  onGridReady(params: GridReadyEvent<EntityTypeGroup>) {
    this.gridApi = params.api;
    this.loadEntityTypeGroups();  
  }

  ngOnInit(): void {
  }

  loadEntityTypeGroups(): void {
    try {        
      this.service.getEntityTypeGroups(
        0, 100).subscribe(
          (data : any) => {
            if (data.total === 0) {
              alert("No entity type groups to display")
            }
            this.rowData = data.results;
          }
      );

    } catch (error : any) {
      
      alert(error.message);

    }
  }

}
