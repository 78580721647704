export enum ProfileClassCode {
    CSTM = "Cut Straight Through",
    DS3LH_CIPSME = "DS3 Low-High CIPS Metro-East",
    DS3MH_CIPS = "DS3 Medium-High CIPS",
    SPSC = "UEC Primary Service Commercial",
    DS2LH_CIPS = "DS2 Low-High CIPS",
    SGSI = "UEC Small General Service Industrial",
    RESDHH_IP = "Residential High-High IP",
    DS3ML_IP = "DS3 Medium-Low IP",
    RESDLL_CIPS = "Residential Low-Low CIPS",
    RESDLH_CIPS = "Residential Low-High CIPS",
    RESDHL_UE = "Residential High-Low UE",
    DS2HL_CIPSME = "DS2 High-Low CIPS Metro-East",
    DS3ML_CIPS = "DS3 Medium-Low CIPS",
    RESDLL_CILCO = "Residential Low-Low CILCO",
    DS2HH_CIPS = "DS2 High-High CIPS",
    RESDHL_IP = "Residential High-Low IP",
    DS2HH_CILCO = "DS2 High-High CILCO",
    DS3HL_CILCO = "DS3 High-Low CILCO",
    IDR = "Interval Data Recorder",
    RESDHL_CIPS = "Residential High-Low CIPS",
    SPSI = "UEC Primary Service Industrial",
    DS2LL_CIPSME = "DS2 Low-Low CIPS Metro-East",
    LGSC = "UEC Large General Service Commercial",
    DS2LL_CIPS = "DS2 Low-Low CIPS",
    RESDLH_CILCO = "Residential Low-High CILCO",
    DS2LH_CIPSME = "DS2 Low-High CIPS Metro-East",
    DS2LH_IP = "DS2 Low-High IP",
    DS3HL_CIPS = "DS3 High-Low CIPS",
    RESDHL_CIPSME = "Residential High-Low CIPS Metro-East",
    RESDLH_UE = "Residential Low-High UE",
    DS2HL_IP = "DS2 High-Low IP",
    RESDLH_IP = "Residential Low-High IP",
    DS3LL_CIPSME = "DS3 Low-Low CIPS Metro-East",
    RESDLH_CIPSME = "Residential Low-High CIPS Metro-East",
    DS2LH_CILCO = "DS2 Low-High CILCO",
    DS3HH_IP = "DS3 High-High IP",
    RESDHH_CILCO = "Residential High-High CILCO",
    DS3MH_IP = "DS3 Medium-High IP",
    DS3HH_CIPS = "DS3 High-High CIPS",
    RESDHL_CILCO = "Residential High-Low CILCO",
    DS3HL_CIPSME = "DS3 High-Low CIPS Metro-East",
    DS3MH_CIPSME = "DS3 Medium-High CIPS Metro-East",
    DS3LH_CILCO = "DS3 Low-High CILCO",
    LITE = "Dusk-to-Dawn Lighting",
    DS3LL_CILCO = "DS3 Low-Low CILCO",
    DS3LL_CIPS = "DS3 Low-Low CIPS",
    DS3LH_IP = "DS3 Low-High IP",
    DS3MH_CILCO = "DS3 Medium-High CILCO",
    RESDHH_CIPS = "Residential High-High CIPS",
    DS3ML_CIPSME = "DS3 Medium-Low CIPS Metro-East",
    DS3LL_IP = "DS3 Low-Low IP",
    RESDHH_CIPSME = "Residential High-High CIPS Metro-East",
    DS2HH_IP = "DS2 High-High IP",
    DS2LL_IP = "DS2 Low-Low IP",
    DS3HL_IP = "DS3 High-Low IP",
    SGSC = "UEC Small General Service Commercial",
    RESDHH_UE = "Residential High-High UE",
    DS2LL_CILCO = "DS2 Low-Low CILCO",
    DS2HH_CIPSME = "DS2 High-High CIPS Metro-East",
    DS3LH_CIPS = "DS3 Low-High CIPS",
    DS3ML_CILCO = "DS3 Medium-Low CILCO",
    DS3HH_CIPSME = "DS3 High-High CIPS Metro-East",
    RESDLL_IP = "Residential Low-Low IP",
    DS3HH_CILCO = "DS3 High-High CILCO",
    DS2HL_CILCO = "DS2 High-Low CILCO",
    LGSI = "UEC Large General Service Industrial",
    DS2HL_CIPS = "DS2 High-Low CIPS",
    RESDLL_UE = "Residential Low-Low UE",
    RESDLL_CIPSME = "Residential Low-Low CIPS Metro-East"
}
