import * as _ from 'lodash';

export abstract class Serializable {
  constructor(values: Object = {}) {
    _.assign(this, values);
  }

  deserialize(values: Object = {}) {
    _.assign(this, values);

    return this;
  }
}
