import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';


import {
  ColDef, 
  RowModelType,
  GridReadyEvent,
  GridApi,
} from 'ag-grid-community';
import { IntervalUsageService } from './service/interval-usage.service';
import { IntervalUsage } from 'src/app/shared/models/intervalUsage';

@Component({
  selector: 'app-interval-usage',
  templateUrl: './interval-usage.component.html',
  styleUrl: './interval-usage.component.scss'
})
export class IntervalUsageComponent { 
  @Input() servicePointId = ""; 

  private gridApi: GridApi;

  public rowData!: IntervalUsage[];

  colDefs: ColDef[] = [
    { 
      field: "market_hour", 
      headerName: "Market Hour",
      valueFormatter: p => 
        this.dateFormatter.transform(p.value, 'short')!, 
      flex: 1,
      sortable: true,
      sort: "asc"
    },
    { 
      field: "kwh", 
      headerName: "Kilowatt-Hours", 
      flex: 1,
      sortable: true 
    },
    { 
      field: "measurement_type_code", 
      headerName: "Measurement Type", 
      flex: 1,
      sortable: true  
    },
    { 
      field: "add_time", 
      headerName: "Add Time",
      flex: 1,
      sortable: true,
      valueFormatter: p => 
        this.dateFormatter.transform(p.value, 'short')!,
    }
  ];

  public rowModelType: RowModelType = "clientSide";
  public paginationPageSize = 20;
  public themeClass: string = "ag-theme-material";

  constructor(private service: IntervalUsageService, private dateFormatter: DatePipe) { }  

  ngAfterViewInit(): void {
  }

  onGridReady(params: GridReadyEvent<IntervalUsage>) {
    this.gridApi = params.api;
    this.loadIntervalUsageData();  
  }

  ngOnInit(): void {
  }

  loadIntervalUsageData(): void {
    try {        
      this.service.getIntervalUsage(
        0, 100,
        this.servicePointId).subscribe(
          (data : any) => {
            if (data.total === 0) {
              alert("No interval usage data to display, try updating your filters")
            }
            this.rowData = data.results;
          }
      );

    } catch (error : any) {
      
      alert(error.message);

    }
  }


}
