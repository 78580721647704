import { Input, Optional, Self } from '@angular/core';
import { HostListener, ElementRef } from '@angular/core';
import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[amerenInputMask]',
})
export class InputMaskDirective {
  @Input() amerenInputMask: string;
  constructor(private el: ElementRef, @Optional() @Self() private control : NgControl) {}

  @HostListener('keyup', ['$event'])
  onkeyUp() {
    let maskPattern = this.amerenInputMask.replace(/9/g, '_');
    let inputNums = [];
    let maskedOutput = '';
    let lastPosition = 1;
    let input = (this.el.nativeElement as HTMLInputElement).value?.trim();

    for (let i = 0; i < input.length; i++) {
      if (!isNaN(Number(input.charAt(i))) && input.charAt(i) != ' ') {
        inputNums.push(input.charAt(i));
      }
    }

    for (let j = 0; j < maskPattern.length; j++) {
      if (maskPattern.charAt(j) == '_') {
        //replace "_" by a number
        if (inputNums.length == 0)
          maskedOutput = maskedOutput + maskPattern.charAt(j);
        else {
          maskedOutput = maskedOutput + inputNums.shift();
          lastPosition = j + 1; //set caret position
        }
      } else {
        maskedOutput = maskedOutput + maskPattern.charAt(j);
      }
    }

    (this.el.nativeElement as HTMLInputElement).value = maskedOutput;
    if(this.control)
    this.control.control?.setValue(maskedOutput);
    (this.el.nativeElement as HTMLInputElement).setSelectionRange(
      lastPosition,
      lastPosition
    );
  }
}
