import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { AmerenCarouselSlide } from "oneuiframework/lib/interface/ameren-carousel-slide.interface";

@Component({
  selector: "app-billing-and-payment-options",
  templateUrl: "./billing-and-payment-options.component.html",
  styleUrls: ["./billing-and-payment-options.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BillingAndPaymentOptionsComponent implements OnInit {
  constructor() {}
  @Input() selectedState = "";
  ngOnInit(): void {}
  getslides = (state: string): AmerenCarouselSlide[] => {
    return state == "illinois"
      ? this.slides_IL
      : state == "missouri"
      ? this.slides_MO
      : [];
  };
  slides_IL: AmerenCarouselSlide[] = [
    {
      img_src: "../../../assets/images/billing-options/familyOnDevices.webp",
      headline: "LIHEAP",
      description:
        "A federally funded program that assists income eligible households with paying their utility bills",
      linkText: "Learn More",
      linkUrl:
        "https://www2.illinois.gov/dceo/communityservices/utilitybillassistance/pages/default.aspx",
      state: "IL",
    },
    {
      img_src: "../../../assets/images/billing-options/Couple.webp",
      headline: "One UI Portal",
      description:
        "Learn about payment assistance options, ways to lower energy bill, and programs",
      linkText: "Learn More",
      linkUrl: "https://www.ameren.com/illinois/residential/energy-assistance",
      state: "IL",
    },
    {
      img_src: "../../../assets/images/billing-options/womanOnLaptop.webp",
      headline: "Payment Agreement",
      description:
        "Residential customers that owe a past due amount may be eligible to make partial payments to stay caught up",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/illinois/account/customer-service/payment-options/payment-assistance/faqs",
      state: "IL",
    },
    {
      img_src: "../../../assets/images/billing-options/family.webp",
      headline: "Gift of Energy",
      description:
        "Make a payment toward the Ameren energy balance of a friend, loved one, neighbor or even local business.",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/illinois/account/customer-service/payment-options/gift-of-energy",
      state: "IL",
    },
    {
      img_src: "../../../assets/images/billing-options/19ManAtHome16x9.webp",
      headline: "Keep Cool Illinois",
      description:
        "A collaborative effort by state agencies working together to serve the state's residents.",
      linkText: "Learn More",
      linkUrl: "https://keepcool.illinois.gov",
      state: "IL",
    },
    {
      img_src:
        "../../../assets/images/billing-options/website-thumbnail-lighting2.jpg",
      headline: "Energy Efficiency Rebate",
      description:
        "Learn more about saving money and energy with Energy Efficiency rebates and incentives.",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/company/environment-and-sustainability/social-responsibility/energy-efficiency-assistance",
      state: "IL",
    },
  ];

  slides_MO: AmerenCarouselSlide[] = [
    {
      img_src: "../../../assets/images/billing-options/LIHEAP.svg",
      headline: "LIHEAP",
      description:
        "A federally funded program that assists income eligible households with paying their utility bills",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/missouri/residential/energy-assistance/liheap",
      state: "MO",
    },
    {
      img_src: "../../../assets/images/billing-options/Clean Slate.svg",
      headline: "Clean Slate",
      description:
        "The Clean Slate program helps customers get assistance catching up now on their past due balance",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/missouri/residential/energy-assistance/clean-slate",
      state: "MO",
    },
    {
      img_src: "../../../assets/images/billing-options/Payment Agreement.svg",
      headline: "Payment Agreement",
      description:
        "Residential customers that owe a past due amount may be eligible to make partial payments to stay caught up",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/missouri/account/customer-service/payment-options/payment-assistance/faqs",
      state: "MO",
    },
    {
      img_src: "../../../assets/images/billing-options/Seasonal Assistance.svg",
      headline: "Seasonal Assistance",
      description:
        "You have multiple options if you need seasonal help paying your bill",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/missouri/residential/energy-assistance/seasonal-assistance",
      state: "MO",
    },
    {
      img_src: "../../../assets/images/billing-options/Current.svg",
      headline: "Keeping Cool / Current ",
      description:
        "A payment assistance program that helps make energy bills more affordable",
      linkText: "Learn More",
      linkUrl:
        "https://www.ameren.com/-/media/missouri-site/files/brochures/keepingcurrentkeepingcoolonly.ashx?la=en-us-mo&hash=72E37F2E77EEFB884BF9BB8BD7E710160233CB4C",
      state: "MO",
    },
    {
      img_src:
        "../../../assets/images/billing-options/Energy Efficiency Rebate.svg",
      headline: "Energy Efficiency Rebate",
      description:
        "Learn more about saving money and energy with Energy Efficiency rebates and incentives",
      linkText: "Learn More",
      linkUrl: "https://www.ameren.com/company/energy-efficiency",
      state: "MO",
    },
  ];
}
