import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { AuthLoginService } from "../services/auth-login.service";

@Injectable()
export class AuthCheckInterceptor implements HttpInterceptor {
  constructor(private service: AuthLoginService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let req = request.clone({
      withCredentials: true
    });

    return next.handle(req).pipe(catchError(err => {
      if (err.status === 401) {
        this.service.login();
      }
      const error = err.error.message || err.statusText;
      return throwError(() =>error);
    }));  
  }
}
