export interface CoincidentPeak {
    entity_code: string;
    entity_name: string;
    peak_hour: string;
    effective_date: Date;
    termination_date: Date;
    entity_type_code: string;
    entity_type_group_code: string;
    entity_uid: string;
    create_time: Date;
    created_by: string;
    last_update_time: Date;
    last_updated_by: string;
}

export class NonCoincidentPeak implements CoincidentPeak {
    entity_code: string = "AMEREN_NCP";
    entity_name: string = "AMEREN_NCP";
    peak_hour: string;
    effective_date: Date;
    termination_date: Date;
    entity_type_code: string = "AMEREN_CAL";
    entity_type_group_code: string = "CAL";
    entity_uid: string;
    create_time: Date;
    created_by: string;
    last_update_time: Date;
    last_updated_by: string;
}

export class MisoCoincidentPeak implements CoincidentPeak {
    entity_code: string = "MISO_CP";
    entity_name: string = "MISO_CP";
    peak_hour: string;
    effective_date: Date;
    termination_date: Date;
    entity_type_code: string = "MISO_CAL";
    entity_type_group_code: string = "CAL";
    entity_uid: string;
    create_time: Date;
    created_by: string;
    last_update_time: Date;
    last_updated_by: string;
}