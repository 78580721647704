export enum EnumToken {
  EAP_DEV_TOKEN = 'e130d306-df3a-4e00-92b4-75cc623854e1',
  EAP_QA_TOKEN = 'cab5f82b-f02c-47ca-b1c2-27222cf19e27',
  EAP_PROD_TOKEN = '9c1f4bb3-f17a-47b0-9b2a-40a4bf2da708',
  RES_DEV_TOKEN = 'c47ff05e-016c-44cb-9e9b-6f34fd3898d2',
  RES_QA_TOKEN = 'f67fd024-0c4e-45b5-81d2-8d96fc3a3942',
  RES_PROD_TOKEN = 'e79c6d42-85e6-48f5-932a-53995a8d2ba3',
  PORTALS_DEV_TOKEN = 'e8007c5c-9f83-4af2-b5bb-cf0dab52d441',
  PORTALS_QA_TOKEN = '5c67d9fe-c0f3-4769-9adf-075375b5ce1c',
  PORTALS_PROD_TOKEN = '6398e024-0904-4fb1-8716-4111ce703057',
}
