import { Component, OnInit, Injectable } from '@angular/core';
import { EssApiService } from 'src/app/shared/services/essapi.service';

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss']
})
@Injectable()
export class HeroComponent implements OnInit {

    essMessage: string = '';
    constructor(private essApi: EssApiService) { }

    ngOnInit(): void {
        this.essApi.getMessage().subscribe(res => {
            this.essMessage = JSON.stringify(res);
            console.log("read from ESS" + this.essMessage)
        });
    }

}
