<div class="entity-managment">

    <h1 style="color:black; text-align:left">Entity Managment</h1>

    <div class="scrolling-container">
        <mat-tab-group animationDuration="0ms">
            <mat-tab label="Entities"> <app-entities></app-entities> </mat-tab>
            <mat-tab label="Entity Types"> <app-entity-types></app-entity-types> </mat-tab>
            <mat-tab label="Entity Type Groups"> <app-entity-type-groups></app-entity-type-groups> </mat-tab>
        </mat-tab-group>
    </div>
</div>