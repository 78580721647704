import { DatePipe } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { CoincidentPeak } from 'src/app/shared/models/coincidentPeak';
import { PlanningData } from 'src/app/shared/models/planningData';

@Component({
  selector: 'coincident-peak',
  templateUrl: './coincident-peak.component.html',
  styleUrl: './coincident-peak.component.scss'
})
export class CoincidentPeakComponent {
  @Input() coincidentPeakData?: PlanningData;
  @Input() formGroupName: string;
  @Input() title: string;

  form: FormGroup;
  
  constructor(@Optional() private rootFormGroup: FormGroupDirective, private dateFormatter: DatePipe) {}

  ngOnInit(): void {
    if (this.rootFormGroup) {
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    }
  }

  formatSeasonDate(data: CoincidentPeak): string {
    return `${this.dateFormatter.transform(data.effective_date, 'mediumDate')} - ${this.dateFormatter.transform(data.termination_date, 'mediumDate')}`
  }
}
