import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CoincidentPeakResponse } from 'src/app/shared/models/coincidentPeakResponse';
import { CoincidentPeak } from 'src/app/shared/models/coincidentPeak';
import { MisoCoincidentPeak } from 'src/app/shared/models/coincidentPeak';
import { NonCoincidentPeak } from 'src/app/shared/models/coincidentPeak';

@Injectable({
  providedIn: 'root'
})
export class PlanningYearService {

  constructor(private http: HttpClient) { }

  getMISOCPData(year?: number) : Observable<CoincidentPeakResponse> {
    return this.getCoincidentPeakData('miso_cp', year)
  }

  getNonCPData(year?: number) : Observable<CoincidentPeakResponse> {
    return this.getCoincidentPeakData('noncoincident-peak', year)
  }

  getCoincidentPeakData(url: string, year?: number) : Observable<CoincidentPeakResponse> {
    let params = new HttpParams();
    
    if (year) {
      let effectiveDate = `>${year}-06-01`
      let terminationDate = `<${year + 1}-09-02`
  
      params = params.set('effective_date', effectiveDate);
      params = params.set('termination_date', terminationDate);
    } else {
      params = params.set('effective_date', `>2020-01-01`);
    }

    const requestOptions = { params: params };
    
    return this.http.get<CoincidentPeakResponse>(url, requestOptions )
    .pipe(catchError(this.handleError));
  }

  createUpdateCoincidentPeakData(data: CoincidentPeak) : Observable<CoincidentPeak> {
    let baseUrl = ""

    switch(data.entity_type_code) {
      case "AMEREN_CAL":
        baseUrl = "noncoincident-peak"
        break;
      case "MISO_CAL":
        baseUrl = "miso_cp"
        break;
      case "TSI":
        baseUrl = "planning_reserve_margin"
        break;
      case "DLF_AMIL":
        baseUrl = "loss-factor"
        break;
    }

    // somewhat poor way to infer that its new
    if (data.create_time) {
      // update

      const body = {
        'termination_date': data.termination_date,
        'entity_detail_value': data.peak_hour
      };

      return this.http.put<CoincidentPeak>(`${baseUrl}/${data.entity_uid}/${data.effective_date.toString()}`, body)
      .pipe(catchError(this.handleError));
    }
    else {
      //create
      const body = {
        'entity_uid': data.entity_uid,
        'effective_date': data.effective_date,
        'termination_date': data.termination_date,
        'entity_detail_value': data.peak_hour
      };

      return this.http.post<CoincidentPeak>(`${baseUrl}`, body)
      .pipe(catchError(this.handleError));
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('There is an issue with the client or network:', error.error);
    } else {
      console.error('Server-side error: ', error.error)
    }

    return throwError(() => new Error('Cannot retrieve coincident peak data from the server. Please try again.'));
  }
}
